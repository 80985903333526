import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './HomePage';
import BrewDetail from './page/BrewDetail';
import CoffeeDetail from './page/CoffeeDetail';
import CoffeePage from './page/CoffeePage';
import Join from './page/Join';
import Login from './page/Login';
import Profile from './page/Profile';
import './index.css';
import BrewPage from './page/BrewPage';
import Forgot from './page/Forgot';
import Terms from './page/TermsAndConditions';

function App() {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/brews/" element={<BrewPage/>}/>
        <Route path="/details/:id" element={<BrewDetail />} />
        <Route path="/coffee-details/:id" element={<CoffeeDetail />} />
        <Route path="/coffee/" element={<CoffeePage />} />
        <Route path="/join/" element={<Join/>}/>
        <Route path="/login" element={<Login/>}/>
        <Route path="/profile" element={<Profile/>}/>
        <Route path="/forgot" element={<Forgot/>}/>
        <Route path="/terms" element={<Terms/>}/>

      </Routes>
    </Router>
  );
}

export default App;
