import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="minFooter mt-auto bg-light">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <p>&copy; {currentYear} Kaffeine App. All rights reserved.</p>
          </div>
          <div className="col-md-6 text-md-end">
            <p>
              Need help? <a href="mailto:support@kaffeineapp.com">Contact Support</a> | 
              <Link to="/terms" className="ms-2">Terms and Conditions</Link>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;