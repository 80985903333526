// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyApW_i6DpOLTNNFBngFZCL4OFgEm49c058",
  authDomain: "kaffeine-eu.firebaseapp.com",
  projectId: "kaffeine-eu",
  storageBucket: "kaffeine-eu.appspot.com",
  messagingSenderId: "339188730326",
  appId: "1:339188730326:web:242d0749147eccc2c0bc77"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const auth = getAuth(app); // Initialize the authentication module

export { db, auth };

//old firebase
// const firebaseConfig = {
//   apiKey: "AIzaSyCjW8AAPVwNISNrkBbEX7XWz33VAOXFMs8",
//   authDomain: "kaffeine-a6c79.firebaseapp.com",
//   projectId: "kaffeine-a6c79",
//   storageBucket: "kaffeine-a6c79.appspot.com",
//   messagingSenderId: "1062512916160",
//   appId: "1:1062512916160:web:03aabd928092d884a31f25"
// };