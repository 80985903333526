import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from "../firebase.config";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

function Login() {

    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const onLogin = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                navigate("/")
                console.log(user);
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage)
                // Update error message state
                setErrorMessage("Invalid email or password. Please try again.");
            });
    }

    return (
        <section className="vh-100">
            <Navbar />

            <div className="d-flex h-100 bg-light">
                <div className="container h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                            <div className="card" style={{ borderRadius: "15px" }}>
                                <div className="card-body p-5">
                                    <h2 className="text-bold text-center ">Welcome Back to Kaffeine</h2>
                                    <p className="d-flex justify-content-center text-center text-muted mt-3 mb-4">
                                        <span>Don't Have an Account?</span>
                                        <div className="join-link ms-2"><Link to='/join/'>Join Kaffeine</Link></div>
                                    </p>

                                    <form>
                                        <div className="mb-4">
                                            <label htmlFor="email" className="pb-2 fw-bold">Email</label>
                                            <input
                                                type="email"
                                                id="email"
                                                label="Email Address"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                                className="form-control"
                                            />
                                        </div>

                                        <div className="mb-4">
                                            <div className="d-flex justify-content-between">
                                                <label htmlFor="password" className="pb-2 fw-bold">Password</label>
                                                <label htmlFor="show-password" className="pb-2 show-password" onClick={togglePasswordVisibility}>{showPassword ? "Hide" : "Show"} Password</label>
                                            </div>
                                            <input
                                                type={showPassword ? "text" : "password"}
                                                id="password"
                                                label="Password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                required
                                                className="form-control"
                                            />
                                        </div>

                                        {/* Display error message if exists */}
                                        {errorMessage && (
                                            <div className="alert alert-danger py-2" role="alert">
                                                {errorMessage}
                                            </div>
                                        )}

                                        <div className="d-flex justify-content-center">
                                            <button
                                                type="button"
                                                className="btn popup-btn rounded-pill"
                                                onClick={onLogin}>Login</button>
                                        </div>
                                        <div className="join-link ms-2"><Link to='/forgot/'>Forgot Password?</Link></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Footer Start --> */}
                <Footer />
            {/* <!-- Footer End --> */}
        </section>
    );

}

export default Login;
