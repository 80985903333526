import React from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

function BrewDetail() {
    const { state } = useLocation();
    const brew = state?.brew;
    
    // Add a logging statement here
    console.log("Location state:", state);

    if (!brew) {
        return <div>No data found</div>;
    }
    // let ingredientsArray = [];
    // // Check if brew.ingredients is an object
    // if (typeof brew.ingredients === 'object' && brew.ingredients !== null) {
    //     // Convert the object into an array of objects
    //     ingredientsArray = Object.keys(brew.ingredients).map(key => ({
    //         ...brew.ingredients[key],
    //         id: key
    //     }));
    // }

    const calculateTotalDuration = () => {
        // Check if brew exists and brew.phases is an array
        if (!brew || !Array.isArray(brew.phases)) {
            return '0:00'; // Return default value if brew or brew.phases is not properly formed
        }
    
        // Use reduce only if brew.phases is an array
        const totalSeconds = brew.phases.reduce((acc, phase) => {
            // Check if phase.duration is a number before adding it to the accumulator
            if (typeof phase.duration === 'number') {
                return acc + phase.duration;
            } else {
                return acc; // Ignore non-numeric durations
            }
        }, 0);
    
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    const calculatePhaseDuration = (duration) => {
        const minutes = Math.floor(duration / 60);
        const seconds = duration % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    const totalDuration = calculateTotalDuration();

    return (
        <div className='wrapper'>
            <div className="dropdown position-fixed bottom-0 end-0 mb-3 me-3 bd-mode-toggle">
                <button className="btn btn-bd-primary py-2 dropdown-toggle d-flex align-items-center" id="bd-theme" type="button"
                    aria-expanded="false" data-bs-toggle="dropdown" aria-label="Toggle theme (auto)">
                    <svg className="bi my-1 theme-icon-active" width="1em" height="1em">
                        <use href="#circle-half"></use>
                    </svg>
                    <span className="visually-hidden" id="bd-theme-text">Toggle theme</span>
                </button>
                <ul className="dropdown-menu dropdown-menu-end shadow" aria-labelledby="bd-theme-text">
                    <li>
                        <button type="button" className="dropdown-item d-flex align-items-center" data-bs-theme-value="light"
                            aria-pressed="false">
                            <svg className="bi me-2 opacity-50 theme-icon" width="1em" height="1em">
                                <use href="#sun-fill"></use>
                            </svg>
                            Light
                            <svg className="bi ms-auto d-none" width="1em" height="1em">
                                <use href="#check2"></use>
                            </svg>
                        </button>
                    </li>
                    <li>
                        <button type="button" className="dropdown-item d-flex align-items-center" data-bs-theme-value="dark"
                            aria-pressed="false">
                            <svg className="bi me-2 opacity-50 theme-icon" width="1em" height="1em">
                                <use href="#moon-stars-fill"></use>
                            </svg>
                            Dark
                            <svg className="bi ms-auto d-none" width="1em" height="1em">
                                <use href="#check2"></use>
                            </svg>
                        </button>
                    </li>
                    <li>
                        <button type="button" className="dropdown-item d-flex align-items-center active" data-bs-theme-value="auto"
                            aria-pressed="true">
                            <svg className="bi me-2 opacity-50 theme-icon" width="1em" height="1em">
                                <use href="#circle-half"></use>
                            </svg>
                            Auto
                            <svg className="bi ms-auto d-none" width="1em" height="1em">
                                <use href="#check2"></use>
                            </svg>
                        </button>
                    </li>
                </ul>
            </div>
            <main>
                {/* <!-- NavBar Start --> */}
                <Navbar />
                {/* <!-- NavBar End --> */}
                <div className="container">
                    {/* <!-- Start of Recipe Title --> */}
                    <div className="recipe-title ms-3 p-3 pt-9 sticky-top text-center">
                        <h1 className=" mt-3 pt-3">{brew.desc}</h1>
                        <div className="text-center p-2">
                            <span className="pe-2">⚡️</span>
                            <span className="username pe-2 py-2 border-end">{brew.username}</span>
                            <span className="name pe-2 py-2" data-type={`${brew.name}`}>{brew.name}</span>
                            <span className="p-2 border-start">👍</span>
                            <span className="rating">{brew.votes}</span>
                        </div>
                    </div>
                    {/* <!-- End of Recipe Title --> */}
                    {/* <!-- Start of Recipe Details --> */}
                    <div className="details ms-3 p-3">
                    <p className="fs-5 col-lg-9 mx-auto">   
                    {brew.details.split("\\n").map((paragraph, index) => (
                        <React.Fragment key={index}>
                        {paragraph}
                        {index < brew.details.split("\\n").length - 1 && <br />}
                        </React.Fragment>
                    ))}
                    </p>
                    </div>
                    {/* <!-- End of Recipe Detaails --> */}

                    <div className="row mx-auto justify-content-md-center">
                        {/* <!-- Start of Roundup --> */}
                        <div className="roundup col-lg-4 ms-3 p-3">
                            <h4>Recipe</h4>
                            <div className="roundup-list">
                                <div className="brew-roundup-row" data-type="coffee_weight">
                                    <span className="roundup-item">Coffee Weight</span>
                                    <span>{brew.ingredients.coffee}g</span>
                                </div>
                                <div className="brew-roundup-row" data-type="coffee_grind">
                                    <span className="roundup-item">Coffee Grind Size</span>
                                    <span>{brew.ingredients.grind}</span>
                                </div>
                                <div className="brew-roundup-row" data-type="water">
                                    <span className="roundup-item">Water</span>
                                    <span>{brew.ingredients.water} ml</span>
                                </div>
                                <div className="brew-roundup-row" data-type="water_temp">
                                    <span className="roundup-item">Water Temperature</span>
                                    <span>{brew.ingredients.temp}ºC</span>
                                </div>
                                <div className="brew-roundup-row" data-type="duration">
                                    <span className="roundup-item">Brewing Time</span>
                                    <span>{brew.ingredients.duration}</span>
                                </div>
                                <div className="brew-roundup-row" data-type="ratio">
                                    <span className="roundup-item">Coffee : Water Ratio</span>
                                    <span>{brew.ingredients.ratio}</span>
                                </div>
                                <div className="brew-roundup-row" data-type="beans">
                                    <span className="roundup-item">Beans</span>
                                    <span>{brew.ingredients.beans}</span>
                                </div>
                            </div>
                        </div>


                        {/* <!-- Start of Phases --> */}
                        <div className="phases col-lg-5 mt-5 mt-md-0 mx-md-3 p-1">
                            <h4>Phases</h4>
                            <ol>
                            {Array.isArray(brew.phases) && brew.phases.map((step, i) => (
                                // Check if step is properly formed before rendering it
                                step && typeof step === 'object' && typeof step.phase === 'string' && typeof step.duration === 'number' && (
                                    <div key={i}>
                                        <li className="phase">
                                            <div className="d-flex justify-content-between">
                                                <div className="text pe-3">{step.phase}</div>
                                                {step.duration !== 0 && ( // Conditionally render duration element
                                                    <div className="duration">{calculatePhaseDuration(step.duration)}</div>
                                                )}
                                            </div>
                                        </li>
                                    </div>
                                )
                            ))}
                            </ol>
                            <div className="text-end"><span>Total Time: {totalDuration}</span></div>
                        </div>
                        {/* <!-- End of Phases --> */}

                    </div>
                </div>
            {/* <!-- Footer Start --> */}
                <Footer />
            {/* <!-- Footer End --> */}
            </main>
        </div >
    );
}

export default BrewDetail;