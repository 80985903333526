import React from 'react';

import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import { useEffect } from "react";
import Navbar from './components/Navbar';
import { Link } from 'react-router-dom';

/* Graphics */
import screen01 from '../src/assets/images/screens/screen-01.png';
import screen02 from '../src/assets/images/screens/screen-02.png';
import screen03 from '../src/assets/images/screens/screen-03.png';
import video1 from '../src/assets/video/kaffeine_preview_dm_h264_414x736_silent_v002.webm';
import tn_guide from '../src/assets/images/school_FILL0_wght400_GRAD0_opsz32.svg';
import tn_custom from '../src/assets/images/tune_FILL0_wght400_GRAD0_opsz32.svg';
import tn_beauty from '../src/assets/images/format_paint_FILL0_wght400_GRAD0_opsz32.svg';
import tn_gui from '../src/assets/images/devices_FILL0_wght400_GRAD0_opsz32.svg';
import tn_updates from '../src/assets/images/system_update_FILL0_wght400_GRAD0_opsz32.svg';
import tn_community from '../src/assets/images/forum_FILL0_wght400_GRAD0_opsz32.svg';
import appstore from '../src/assets/images/appstore.png';


function HomePage() {

    // Mailchimp form
    useEffect(() => {
        const mcForm = document.querySelector('#mc-form')
        const mcStatus = document.querySelector('.mc-status'); // Define mcStatus here

        if (!mcForm) return;

        mcForm.setAttribute('novalidate', true);

        function hasError(field) {
            if (field.disabled || field.type === 'file' || field.type === 'reset' || field.type === 'submit' || field.type === 'button') return;

            let validity = field.validity;

            if (validity.valid) return;

            if (validity.valueMissing) return 'Please enter an email address.';

            if (validity.typeMismatch) {
                if (field.type === 'email') return 'Please enter a valid email address.';
            }

            if (validity.patternMismatch) {
                if (field.hasAttribute('title')) return field.getAttribute('title');
                return 'Please match the requested format.';
            }

            return 'The value you entered for this field is invalid.';
        }

        function showError(field, error) {
            let errorMessage = field.form.querySelector('.mc-status');
            errorMessage.classList.remove('success-message');
            errorMessage.classList.add('error-message');
            errorMessage.innerHTML = error;
        }

        window.displayMailChimpStatus = function (data) {
            if (!data.result || !data.msg || !mcStatus) return;
            mcStatus.innerHTML = data.msg;
            if (data.result === 'error') {
                mcStatus.classList.remove('success-message');
                mcStatus.classList.add('error-message');
                return;
            }
            mcStatus.classList.remove('error-message');
            mcStatus.classList.add('success-message');
        };

        function submitMailChimpForm(form) {
            let url = 'https://conorbyrne.us11.list-manage.com/subscribe/post-json?u=a3ab7899a801b68083b1a24a4&id=3382ebec89&c=displayMailChimpStatus';
            let emailField = form.querySelector('#mce-EMAIL');
            let serialize = '&' + encodeURIComponent(emailField.name) + '=' + encodeURIComponent(emailField.value);

            if (url === '') return;

            url += serialize;

            var ref = window.document.getElementsByTagName('script')[0];
            var script = window.document.createElement('script');
            script.src = url;

            window.mcStatus = form.querySelector('.mc-status');
            window.mcStatus.classList.remove('error-message', 'success-message');
            window.mcStatus.innerText = 'Submitting...';

            ref.parentNode.insertBefore(script, ref);

            script.onload = function () {
                this.remove();
            };
        }

        mcForm.addEventListener('submit', function (event) {
            event.preventDefault();
            let emailField = event.target.querySelector('#mce-EMAIL');
            let error = hasError(emailField);

            if (error) {
                showError(emailField, error);
                emailField.focus();
                return;
            }

            submitMailChimpForm(this);

        }, false);

        return () => {
            // mcForm.removeEventListener('submit', submitHandler);
        };


    }, []);

    return (

        <main>
            {/* <!-- NavBar Start --> */}
            <Navbar />
            {/* <!-- NavBar End --> */}

            {/* <!-- Start of Banner --> */}
            <section id="banner" className="banner">

                <div className="banner_bg"></div>

                <div className="custom_row banner_content">

                    <div className="column lg-12 banner_content_inner">
                        <div className="banner_content_left">
                            <h1 className="banner_content_title">
                                What do you want <br></br>
                                to Brew Today?
                            </h1>
                        </div>
                        <div className="banner_content_right">
                            <p className="body-text-2">
                                Introducing Kaffeine - The Ultimate Coffee Brewing Companion!
                            </p>

                            <div className="banner_content_buttons">
                                <a href="#download" className="btn popup-btn rounded-pill">Get Started</a>
                                <a href="https://player.vimeo.com/video/14592941?color=00a650&title=0&byline=0&portrait=0" className="btn play-btn rounded-pill">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7 6v12l10-6z"></path></svg>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>

            </section>

            {/* <!-- End of Banner --> */}

            {/* <!-- Start of About --> */}

            <section id="about" className="about">

                <div className="about_info">

                    <div className="custom_row section-header" data-num="01">
                        <div className="column lg-6 stack-on-1000 title-block">
                            <h2 className="title text-display-1">Getting Started With Kaffeine.</h2>
                        </div>
                        <div className="column lg-6 stack-on-1000 desc-block">
                            <p className="desc lead">
                                Your virtual assistant for crafting the perfect cup! Explore, learn, and brew like never before!
                                Whether you're a seasoned barista or a home-brew enthusiast, our app caters to every level of expertise.
                            </p>
                        </div>
                    </div>


                    <div className="about_snips">
                        <div className="custom_row">
                            <div className="col-sm me-lg-5 px-3 px-sm-0">
                                <h4 className="h5 ">Follow our Guides.</h4>

                                <p className="lh-lg">
                                    Explore brewing methods with detailed guides, including measurements tailored to metric, imperial, and kitchen units, timings, and expert recipes.
                                    From the delicate art of pour-over to the boldness of moka, unlock the secrets to elevate your brewing skills.
                                </p>
                            </div>
                            <div className="col-sm me-lg-5 px-3 px-sm-0">
                                <h4 className="h5">Store your Beans.</h4>

                                <p className="lh-lg">
                                    Add your newly acquired beans, detailing their unique attributes and jotting down any flavor notes that accompany them.
                                    Whether it's the origin, roast level, or tasting profile, capture it all here. Elevate your coffee journey by keeping a personalized record of your favorite beans"
                                </p>
                            </div>
                            <div className="col-sm me-lg-5 px-3 px-sm-0">
                                <h4 className="h5">Log your Brews.</h4>

                                <p className="lh-lg">
                                    Document your brewing adventures in our intuitive brew log: document measurements, timings, and personal notes for each cup.
                                    Share your unique recipes and discoveries with friends in the Kaffeine community, turning every brew into a shared experience.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>


                {/* <!-- End of About --> */}

                {/* <!-- Start of Screenshots --> */}

                <div className="about_screens">
                    <div className="custom_row px-2">
                        <div className="col-sm me-0 me-sm-5 screen px-2 mb-5">
                            <img src={screen03} alt={"screenshot03"} />
                        </div>
                        <div className="col-sm me-0 me-sm-5 screen px-2 mb-5">
                            <img src={screen02} alt={"screenshot02"} />
                        </div>
                        <div className="col-sm me-0 me-sm-5 screen px-2 mb-5">
                            <img src={screen01} alt={"screenshot01"} />
                        </div>
                    </div>
                </div>

                {/* <!-- End of Screenshots --> */}

                {/* <!-- Start of How2 --> */}

                <div className="about_how2">

                    <div className="custom_row section-header" data-num="02">
                        <div className="column lg-6 stack-on-1000 title-block">
                            <h2 className="title text-display-1">How the App Works.</h2>
                        </div>
                    </div>

                    <div className="custom_row about_how2_content">

                        <div className="column about_how2_process_block">

                            <div className="about_how2_process">
                                <div className="about_how2_process_step">
                                    <h3 className="h5">Learn</h3>
                                    <p>
                                        Follow our expert created guides for some of the most popular brewing methods, from Aeropress, Moka Pot and Chemex to Drip Coffee.
                                        More guides will be added all the time.
                                    </p>
                                </div>
                                <div className="about_how2_process_step">
                                    <h3 className="h5">Time</h3>
                                    <p>
                                        Never miss a beat with our integrated coffee timer.
                                        Our built-in timer ensures that every cup is brewed to perfection, whether using a French press, pour-over, or any other method, all presented in your preferred units.
                                    </p>
                                </div>
                                <div className="about_how2_process_step">
                                    <h3 className="h5">Brew Log & Social Sharing:</h3>
                                    <p>
                                        Document your brewing adventures in our intuitive brew log: document measurements, timings, and personal notes for each cup.
                                        Share your unique recipes and discoveries with friends in the Kaffeine community turning every brew into a shared experience.
                                    </p>
                                </div>
                                <div className="about_how2_process_step">
                                    <h3 className="h5">Customize</h3>
                                    <p>
                                        Create your personalized brew profiles to match your unique taste preferences.
                                        Access your favourite settings with just a few taps for a consistent and delightful coffee experience.

                                    </p>
                                </div>
                            </div>

                        </div>

                        <div className="column about_how2_screens_block">

                            <div className="about_how2_screens">
                                <video src={video1} width="340" height="736" autoPlay={true} loop={true} muted={true} />
                            </div>

                        </div>

                    </div>

                </div>

                {/* <!-- End of How2 --> */}

                {/* <!-- Start of Why --> */}

                <div className="about_why">

                    <div className="custom_row section-header" data-num="03">
                        <div className="column lg-6 stack-on-1000 title-block">
                            <h2 className="title text-display-1">Why Choose Kaffeine?</h2>
                        </div>
                        <div className="column lg-6 stack-on-1000 desc-block">
                            <p className="desc lead">
                                Kaffeine aims to be the best digital coffee assistant on the block.
                                Here's why it's a must-have for every coffee enthusiast:
                            </p>
                        </div>
                    </div>


                    <div className="about_why_deets">
                        <div className="custom_row row-cols-sm-3 mb-sm-5">
                            <div className="col px-3 px-sm-0">
                                <div className="about_why_icon_block mb-3">
                                    <img src={tn_guide} width="32" height="32" alt=""></img>
                                </div>

                                <h4 className="h5 ">Expert-Approved Techniques</h4>

                                <p className="lh-lg pb-3">
                                    We've tried to create the best possible recipes for every brewing method,
                                    and have pored over our recipes to make sure you get the perfect cup evry time.
                                </p>
                            </div>
                            <div className="col px-3 px-sm-0">
                                <div className="about_why_icon_block mb-3">
                                    <img src={tn_custom} width="32" height="32" alt=""></img>
                                </div>

                                <h4 className="h5">Customization</h4>

                                <p className="lh-lg pb-3">
                                    Coffee is a subjective thing though, so Kaffeine allows you to totally customize every element of your brew,
                                    while also helping you keep on eye on you ratio and extractions.
                                </p>
                            </div>
                            <div className="col px-3 px-sm-0">
                                <div className="about_why_icon_block mb-3">
                                    <img src={tn_beauty} width="32" height="32" alt=""></img>
                                </div>

                                <h4 className="h5">Beautiful</h4>

                                <p className="lh-lg pb-3">
                                    Beauty is in the eye of the beholder but we things Kaffeine is a stunner, with gorgeous high resolution 3d renders, animations and icons.
                                    You can choose between 2d and 3d skins, and we plan on adding more customization in the near future.
                                </p>
                            </div>
                            <div className="col px-3 px-sm-0">
                                <div className="about_why_icon_block mb-3">
                                    <img src={tn_gui} width="32" height="32" alt=""></img>
                                </div>

                                <h4 className="h5">User-Friendly Interface</h4>

                                <p className="lh-lg pb-3">
                                    Our aim is to have an accessible app which beginners and pros will feal comfortable using.
                                    With large graphics, and intuitive controls the UI can get out of your way and allow you to concentrate on perfecting your daily dose.
                                </p>
                            </div>
                            <div className="col px-3 px-sm-0">
                                <div className="about_why_icon_block mb-3">
                                    <img src={tn_updates} width="32" height="32" alt=""></img>
                                </div>

                                <h4 className="h5">Regular Updates with New Content</h4>

                                <p className="lh-lg pb-3">
                                    While we feel that Kaffeine is pretty full featured as it is,
                                    we intend to add more recipes as time goes by, as well as adding more features and skins. Stay Tuned.
                                </p>
                            </div>
                            <div className="col px-3 px-sm-0">
                                <div className="about_why_icon_block mb-3">
                                    <img src={tn_community} width="32" height="32" alt=""></img>
                                </div>

                                <h4 className="h5">Community First</h4>

                                <p className="lh-lg pb-3">
                                    We hope to engage with the kaffeine community by sharing our upcoming features and roadmap, and by inviting feedback from users. Below is a link to our roadmap:
                                    <a href="https://trello.com/invite/b/hw4HyzKZ/ATTId9f011f93742f7b95fbef61ba610d4f24B3A7B13/kaffeine-roadmap" >Roadmap</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- End of Why --> */}

            </section>

            {/* End of About Section*/}

            {/* Start of Download bar */}
            <section id="download" className="download target-section">
                <div className="custom_row download_header">
                    <div className="column lg-12">
                        <h2 className="text-display-1">Join Our Community today and start on the road to a perfect brew. </h2>
                        <p className="lead">
                            Download Kaffeine now and be among the first to experience the future of coffee brewing!
                            So, what do you want to Brew today?
                        </p>
                    </div>
                </div>
                <div className="custom_row download_badges_block">
                    <div className="column lg-12 download_badges">
                        <a href="https://apps.apple.com/us/app/kaffeine-coffee-brewing-guide/id6463932858" title="Kaffeine" className="badge-appstore">
                            <img src={appstore} alt=""></img>
                        </a>
                        {/* <a href="#0" title="" className="badge-googleplay">
                            <img src={playstore} alt=""></img>
                        </a> */}
                    </div>
                </div>
            </section>
            {/* End of Download bar */}

            <footer id="colophon" className="footer">

                <div className="custom_row">
                    <div className="column lg-12 text-center">
                        <h2 className="text-display-1">
                            Sign Up to Our Newsletter.
                        </h2>
                        <p className="lead">
                            Subscribe for updates, special offers and more.
                        </p>
                    </div>
                    <div className="column lg-12 footer_subscribe">
                        <div className="subscribe-form">
                            <form id="mc-form" className="mc-form">
                                <input
                                    type="email"
                                    name="EMAIL"
                                    id="mce-EMAIL"
                                    className="text-center"
                                    placeholder="Your Email Address"
                                    title="The domain portion of the email address is invalid (the portion after the @)."
                                    pattern="^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*(\.\w{2,})+$"
                                    required
                                ></input>
                                <input type="submit" name="subscribe" value="Subscribe" className="btn submit"></input>
                                <div className="mc-status"></div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="custom_row">
                    <div className="column lg-5 md-6 stack-on-900">

                        <ul className="footer_social">
                            <li>
                                <a href="https://www.facebook.com">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="white"
                                    >
                                        <path d="M20,3H4C3.447,3,3,3.448,3,4v16c0,0.552,0.447,1,1,1h8.615v-6.96h-2.338v-2.725h2.338v-2c0-2.325,1.42-3.592,3.5-3.592 c0.699-0.002,1.399,0.034,2.095,0.107v2.42h-1.435c-1.128,0-1.348,0.538-1.348,1.325v1.735h2.697l-0.35,2.725h-2.348V21H20 c0.553,0,1-0.448,1-1V4C21,3.448,20.553,3,20,3z" />
                                    </svg>
                                    {/* <span className="u-screen-reader-text">Facebook</span> */}
                                </a>
                            </li>
                            <li>
                                <a href="https://www.twitter.com">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="white"
                                    >
                                        <path d="M19.633,7.997c0.013,0.175,0.013,0.349,0.013,0.523c0,5.325-4.053,11.461-11.46,11.461c-2.282,0-4.402-0.661-6.186-1.809 c0.324,0.037,0.636,0.05,0.973,0.05c1.883,0,3.616-0.636,5.001-1.721c-1.771-0.037-3.255-1.197-3.767-2.793 c0.249,0.037,0.499,0.062,0.761,0.062c0.361,0,0.724-0.05,1.061-0.137c-1.847-0.374-3.23-1.995-3.23-3.953v-0.05 c0.537,0.299,1.16,0.486,1.82,0.511C3.534,9.419,2.823,8.184,2.823,6.787c0-0.748,0.199-1.434,0.548-2.032 c1.983,2.443,4.964,4.04,8.306,4.215c-0.062-0.3-0.1-0.611-0.1-0.923c0-2.22,1.796-4.028,4.028-4.028 c1.16,0,2.207,0.486,2.943,1.272c0.91-0.175,1.782-0.512,2.556-0.973c-0.299,0.935-0.936,1.721-1.771,2.22 c0.811-0.088,1.597-0.312,2.319-0.624C21.104,6.712,20.419,7.423,19.633,7.997z" />
                                    </svg>
                                    {/* <span className="u-screen-reader-text">Twitter</span> */}
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="white"
                                    >
                                        <path d="M11.999,7.377c-2.554,0-4.623,2.07-4.623,4.623c0,2.554,2.069,4.624,4.623,4.624c2.552,0,4.623-2.07,4.623-4.624 C16.622,9.447,14.551,7.377,11.999,7.377L11.999,7.377z M11.999,15.004c-1.659,0-3.004-1.345-3.004-3.003 c0-1.659,1.345-3.003,3.004-3.003s3.002,1.344,3.002,3.003C15.001,13.659,13.658,15.004,11.999,15.004L11.999,15.004z"></path><circle cx="16.806" cy="7.207" r="1.078"></circle><path d="M20.533,6.111c-0.469-1.209-1.424-2.165-2.633-2.632c-0.699-0.263-1.438-0.404-2.186-0.42 c-0.963-0.042-1.268-0.054-3.71-0.054s-2.755,0-3.71,0.054C7.548,3.074,6.809,3.215,6.11,3.479C4.9,3.946,3.945,4.902,3.477,6.111 c-0.263,0.7-0.404,1.438-0.419,2.186c-0.043,0.962-0.056,1.267-0.056,3.71c0,2.442,0,2.753,0.056,3.71 c0.015,0.748,0.156,1.486,0.419,2.187c0.469,1.208,1.424,2.164,2.634,2.632c0.696,0.272,1.435,0.426,2.185,0.45 c0.963,0.042,1.268,0.055,3.71,0.055s2.755,0,3.71-0.055c0.747-0.015,1.486-0.157,2.186-0.419c1.209-0.469,2.164-1.424,2.633-2.633 c0.263-0.7,0.404-1.438,0.419-2.186c0.043-0.962,0.056-1.267,0.056-3.71s0-2.753-0.056-3.71C20.941,7.57,20.801,6.819,20.533,6.111z M19.315,15.643c-0.007,0.576-0.111,1.147-0.311,1.688c-0.305,0.787-0.926,1.409-1.712,1.711c-0.535,0.199-1.099,0.303-1.67,0.311 c-0.95,0.044-1.218,0.055-3.654,0.055c-2.438,0-2.687,0-3.655-0.055c-0.569-0.007-1.135-0.112-1.669-0.311 c-0.789-0.301-1.414-0.923-1.719-1.711c-0.196-0.534-0.302-1.099-0.311-1.669c-0.043-0.95-0.053-1.218-0.053-3.654 c0-2.437,0-2.686,0.053-3.655c0.007-0.576,0.111-1.146,0.311-1.687c0.305-0.789,0.93-1.41,1.719-1.712 c0.534-0.198,1.1-0.303,1.669-0.311c0.951-0.043,1.218-0.055,3.655-0.055c2.437,0,2.687,0,3.654,0.055 c0.571,0.007,1.135,0.112,1.67,0.311c0.786,0.303,1.407,0.925,1.712,1.712c0.196,0.534,0.302,1.099,0.311,1.669 c0.043,0.951,0.054,1.218,0.054,3.655c0,2.436,0,2.698-0.043,3.654H19.315z"></path></svg>
                                    {/* <span className="u-screen-reader-text">Instagram</span> */}
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="column lg-6 stack-on-900 end">

                        <p className="footer_contact">
                            For Support Requests Please Email: <br></br>
                            <a href="mailto:support@kaffeineapp.com" className="footer_email">support@kaffeineapp.com</a>
                        </p>


                    </div>

                    <div className="column lg12 text-center copyright">
                        <p>© Ubåt 2024</p>   <Link to="/terms" className="ms-2">Terms and Conditions</Link>
                    </div>

                </div>
            </footer>

        </main >
    );
}

export default HomePage;

