import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import 'firebase/compat/auth';

import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

function Forgot() {

    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [resetSent, setResetSent] = useState(false);
    const auth = getAuth();


    const handleReset = async () => {
        try {
            await sendPasswordResetEmail(auth, email);
            setResetSent(true);
        } catch (error) {
            setErrorMessage(error.message);
        }
    };

    return (
        <section className="vh-100">
            <Navbar />
            <div className="d-flex h-100 bg-light">
                <div className="container h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                            <div className="card" style={{ borderRadius: "15px" }}>
                                {resetSent ? (
                                    <div className="card-body p-5">
                                        <h2 className="text-bold text-center mb-3">Done and Done</h2>
                                        <p>Success! We've sent a password reset email to the provided email address.</p>
                                        <button
                                            type="button"
                                            className="btn popup-btn rounded-pill"
                                        ><Link to='/login/'>Return to Login?</Link></button>
                                    </div>
                                ) : (
                                    <div className="card-body p-5">
                                        <h2 className="text-bold text-center mb-3">Reset Your Password</h2>
                                        <p>If you've forgotten your password, enter your email address below and we'll send you a reset link.
                                            If the link doesn't show up right away, please check your spam folder. Cheers.</p>

                                        <form>
                                            <div className="mb-4">
                                                <label htmlFor="email" className="pb-2 fw-bold mt-3">Email</label>
                                                <input
                                                    type="email"
                                                    id="email"
                                                    label="Email Address"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    required
                                                    className="form-control"
                                                />
                                            </div>

                                            {/* Display error message if exists */}
                                            {errorMessage && (
                                                <div className="alert alert-danger py-2" role="alert">
                                                    {errorMessage}
                                                </div>
                                            )}

                                            <div className="d-flex justify-content-center">
                                                <button
                                                    type="button"
                                                    className="btn popup-btn rounded-pill"
                                                    onClick={handleReset}>Reset Password</button>
                                            </div>
                                            <div className="join-link ms-2"><Link to='/login/'>Return to Login?</Link></div>
                                        </form>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Footer Start --> */}
                <Footer />
            {/* <!-- Footer End --> */}
        </section>

    );
}

export default Forgot;