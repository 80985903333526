import React from 'react';
import { Link } from 'react-router-dom';

function Card({ brew, onVote }) {

    const handleVoteClick = (e) => {
        e.preventDefault();
        onVote();
    };

    return (
        <div className={`col ${brew.name}`}>
            <Link to={`/details/${brew.id}`} state={{ brew: brew }}>
                <div className={`inner p-3 border bg-white rounded ${brew.name} h-100`}>
                    <div className="flex">
                        <span className="pe-2">⚡️</span>
                        <span className="username text-sm">{brew.username}</span>
                        <div className="ms-auto">
                            <span onClick={handleVoteClick} className="vote-btn p-2">👍</span>
                            <span className="total_score">{brew.votes}</span>
                        </div>
                    </div>
                    <h4 className="card-title text-lg mt-2">{brew.desc}</h4>
                    <div className="card-description mt-2"><div className='desc-inner'>{brew.details}</div></div>
                    <div className="col small" id="date-added" data-date-added={`${brew.date}`}></div>
                </div>
            </Link>
        </div>
    );
}

export default Card;