import React from 'react';
import { Link } from 'react-router-dom';

function Card({ bean, onVote }) {

    const handleVoteClick = (e) => {
        e.preventDefault();
        onVote();
    };

    return (
        <div className={`col ${bean.id}`}>
            <Link to={`/coffee-details/${bean.id}`} state={{ bean: bean }}>
                <div className={`inner p-3 border bg-white rounded h-100`}>
                    <div className="flex">
                        {/* <span className="pe-2">⚡️</span> */}
                        <span className="card-brand text-lg">{bean.brand}</span>
                        <div className="ms-auto">
                            <span onClick={handleVoteClick} className="vote-btn p-2">👍</span>
                            <span className="total_score">{bean.votes}</span>
                        </div>
                    </div>
                    <h4 className="card-product text-lg mt-2">{bean.product}</h4>
                    <div className="card-description mt-2"><div className='desc-inner'>{bean.description}</div></div>
                    <div className="col small" id="date-added" data-date-added={`${bean.date}`}></div>
                </div>
            </Link>
        </div>
    );
}

export default Card;