import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the styles

function AddCoffeePopup({ isOpen, onClose, popupActive, form, setForm, handleDateChange, handleSubmit, setPopupActive }) {

    return (
        popupActive && <div className="popup">
            <div className="popup-inner">
                <div className="d-flex justify-content-between">
                    <h3>Add some new Coffee Beans</h3>
                    <button type="button" class="btn-close" aria-label="Close" onClick={() => setPopupActive(false)}></button>
                </div>
                <hr></hr>

                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <div className="row">
                            <div className="coffee_label">
                                <label htmlFor="brand" className="col-form-label coffee_brand">Brand</label>
                            </div>
                            <div className="coffee_value">

                                <input
                                    type="text"
                                    className="form-control"
                                    id="brand"
                                    value={form.brand}
                                    placeholder="Brand"
                                    required
                                    onChange={e => setForm({ ...form, brand: e.target.value })} /></div>
                        </div>
                        <div className="row">
                            <div className="coffee_label">
                                <label htmlFor="product" className="col-form-label product-label">Coffee Beans </label>
                            </div>
                            <div className="coffee_value">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="beans"
                                    placeholder="Product"
                                    value={form.product}
                                    required
                                    onChange={e => setForm({ ...form, product: e.target.value })} /></div>
                        </div>
                        <div className="row">
                            <div className="coffee_label">
                                <label htmlFor="description" className="col-form-label desc-label">Description</label>
                            </div>
                            <div className="coffee_value">

                                <textarea
                                    type="text"
                                    className="form-control"
                                    id="description"
                                    placeholder="Description"
                                    value={form.description}
                                    required
                                    onChange={e => setForm({ ...form, description: e.target.value })} /></div>
                        </div>
                        <div className="row">
                            <div className="coffee_label">
                                <label htmlFor="desc" className="col-form-label weight-label">Weight (g)</label></div>
                            <div className="coffee_value">

                                <input
                                    type="number"
                                    id="weight"
                                    placeholder="Bag Weight in grams"
                                    className="form-control"
                                    value={form.weight}
                                    required
                                    onChange={e => setForm({ ...form, weight: e.target.value })} /></div>
                        </div>
                        <div className="row">
                            <div className="coffee_label">
                                <label htmlFor="roast_profile" className="col-form-label roast-profile-label">Roast Profile</label></div>
                            <div className="coffee_value">

                                <input
                                    type="text"
                                    id="roast_profile"
                                    placeholder="Roast Profile"
                                    className="form-control"
                                    value={form.roast_profile}
                                    required
                                    onChange={e => setForm({ ...form, roast_profile: e.target.value })} /></div>
                        </div>
                        <div className="row">
                            <div className="coffee_label">
                                <label htmlFor="hyperlink" className="col-form-label link-label">Hyperlink</label></div>
                            <div className="coffee_value">

                                <input
                                    type="text"
                                    id="hyperlink"
                                    placeholder="Link to Roaster"
                                    className="form-control"
                                    value={form.hyperlink}
                                    required
                                    onChange={e => setForm({ ...form, hyperlink: e.target.value })} /></div>
                        </div>
                        <div className="row">
                            <div className="coffee_label">
                                <label htmlFor="origin" className="col-form-label origin-label">Origin</label></div>
                            <div className="coffee_value">

                                <input
                                    type="text"
                                    id="origin"
                                    placeholder="Origin"
                                    className="form-control"
                                    value={form.origin}
                                    required
                                    onChange={e => setForm({ ...form, origin: e.target.value })} /></div>
                        </div>
                        <div className="row">
                            <div className="coffee_label">
                                <label htmlFor="process" className="col-form-label process-label">Process</label>
                            </div>
                            <div className="coffee_value">
                                <input
                                    type="text"
                                    id="process"
                                    placeholder="Process"
                                    className="form-control"
                                    value={form.process}
                                    required
                                    onChange={e => setForm({ ...form, process: e.target.value })} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="coffee_label">
                                <label htmlFor="notes" className="col-form-label notes-label">Notes</label>
                            </div>
                            <div className="coffee_value">

                                <input
                                    type="text"
                                    id="notes"
                                    placeholder="Coffee Notes"
                                    className="form-control"
                                    value={form.notes}
                                    required
                                    onChange={e => setForm({ ...form, notes: e.target.value })} /></div>
                        </div>
                        <div className="row">
                            <div className="coffee_label">
                                <label htmlFor="producer" className="col-form-label producer-label">Producer</label>
                            </div>
                            <div className="coffee_value">

                                <input
                                    type="text"
                                    id="producer"
                                    placeholder="Producer"
                                    className="form-control"
                                    value={form.producer}
                                    onChange={e => setForm({ ...form, producer: e.target.value })} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="coffee_label">
                                <label htmlFor="grind" className="col-form-label sug-grind-label">Suggested Grind</label></div>
                            <div className="coffee_value">

                                <input
                                    type="text"
                                    id="grind"
                                    placeholder="Suggested Grind"
                                    className="form-control"
                                    value={form.grind}
                                    required
                                    onChange={e => setForm({ ...form, grind: e.target.value })} /></div>
                        </div>
                        <div className="row">
                            <div className="coffee_label">
                                <label htmlFor="varietal" className="col-form-label varietal-label">Varietal</label></div>
                            <div className="coffee_value">
                                <input
                                    type="text"
                                    id="varietal"
                                    placeholder="Varietal"
                                    className="form-control"
                                    value={form.varietal}
                                    required
                                    onChange={e => setForm({ ...form, varietal: e.target.value })} /></div>
                        </div>
                        <div className="row">
                            <div className="coffee_label">
                                <label htmlFor="altitude" className="col-form-label altitude-label">Altitude</label></div>
                            <div className="coffee_value">
                                <input
                                    type="number"
                                    id="altitude"
                                    placeholder="Altitude"
                                    className="form-control"
                                    value={form.altitude}
                                    required
                                    onChange={e => setForm({ ...form, altitude: e.target.value })} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="coffee_label">
                                <label htmlFor="country" className="col-form-label country-label">Country of Roaster</label>
                            </div>
                            <div className="coffee_value">
                                <input
                                    type="text"
                                    id="country"
                                    placeholder="Country"
                                    className="form-control"
                                    value={form.country}
                                    required
                                    onChange={e => setForm({ ...form, country: e.target.value })} />
                            </div>
                        </div>
                        <div className="row">
                        <div className="coffee_label">
                                <label htmlFor="category" className="col-form-label category-label">Category (craft / chain etc.)</label>
                            </div>
                            <div  className="coffee_value">
                                <select
                                    className="form-select"
                                    aria-label="Category Select"
                                    id="category"
                                    value={form.category}
                                    required
                                    onChange={e => setForm({ ...form, category: e.target.value })}>
                                    <option value="" disabled>Select a Category</option>
                                    <option value="craft">Craft</option>
                                    <option value="supermarket">Supermarket</option>
                                    <option value="chain">Chain</option>
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="coffee_label">
                                <label htmlFor="roast_date" className="col-form-label roast-date-label">Roast Date</label></div>
                            <div className="coffee_value">
                                <DatePicker
                                    selected={form.roast_date}
                                    placeholderText={'Please select a date'}
                                    onChange={handleDateChange}
                                    dateFormat="dd/MM/yyyy"
                                /></div>
                        </div>
                    </div>

                    <div className="buttons"></div>
                    <button type="submit" className="btn popup-btn rounded-pill me-2">Submit</button>
                    <button type="button" className="btn popup-btn rounded-pill" onClick={() => setPopupActive(false)}>Close</button>
                </form>
            </div>
        </div >
    );
}

export default AddCoffeePopup;