
import logo from '../assets/images/ic_kaffeine_logo_250px.png';
import { useNavigate, Link } from "react-router-dom";
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";

function Navbar() {
    const navigate = useNavigate();
    const auth = getAuth();
    const user = auth.currentUser;  
    const url = "https://apps.apple.com/us/app/kaffeine-coffee-brewing-guide/id6463932858"

    const OnLogout = async (e) => {

        signOut(auth).then(() => {
            console.log("Sign-out successful.")
            navigate("/")
        }).catch((error) => {
            // An error happened.
        });
    }

    onAuthStateChanged(auth, (user) => {
        if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/auth.user
            // ...
        } else {
            // User is signed out
            // ...
        }
    });

    return (

        <nav className="navbar navbar-expand-md navbar-dark fixed-top">
            <div className="container-fluid align-items-center">
                <div className="header-logo">
                    <Link to='/'><img src={logo} alt="Kaffeine"></img></Link>
                </div>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <ul className="navbar-nav ms-3 me-auto">
                        <li className="nav-item">
                            <div className="nav-link" href="#" tabIndex="-1" aria-current="page"><Link to='../'>Home</Link></div>
                        </li>
                        <li className="nav-item">
                            <div className="nav-link" href="#" tabIndex="-1" aria-disabled="true"><Link to='/brews/'>Recipes</Link></div>
                        </li>
                        <li className="nav-item">
                            <div className="nav-link" href="#" tabIndex="-1" aria-disabled="true"><Link to='/coffee/'>Coffee Beans</Link></div>
                        </li>
                    </ul>
                    <div className="flex profile-panel align-items-center me-sm-4 m-0">
                        {user
                            ? <ul className="navbar-nav mb-2 mb-md-0 pe-sm-3 border-end me-sm-4 border-gray">
                                <li className="nav-item">
                                    <div className="nav-link"><Link to='/profile'>Profile</Link></div>
                                </li>
                                <li className="nav-item">
                                    <div className="nav-link" onClick={OnLogout}><Link to='/'>Log Out</Link></div>
                                </li>
                            </ul>
                            : <ul className="navbar-nav mb-2 mb-md-0 pe-sm-3 border-end me-sm-4 border-gray">
                                <li className="nav-item">
                                    <div className="nav-link"><Link to='/login/'>Log In</Link></div>
                                </li>
                                <li className="nav-item">
                                    <div className="nav-link"><Link to='/join/'>Join</Link></div>
                                </li>
                            </ul>
                        }
                        <button type="button" onClick={() => window.open(url, '_blank')} className="btn popup-btn rounded-pill">Get The App</button>
                    </div>
                </div>
            </div>
        </nav>
    );

}

export default Navbar;