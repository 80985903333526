import React from 'react';

function AddRecipePopup({
    popupActive,
    form,
    setForm,
    handleSubmit,
    handleIngredients,
    handlePhaseNameChange,
    handlePhaseDurationChange,
    handlePhaseCount,
    setPopupActive,
    handleCupsChange,
    cupsOptions,
    handlePhaseDelete
}) {

    return (
        popupActive && <div className="popup">
            <div className="popup-inner">
                <div className="d-flex justify-content-between">
                    <h3>Add a new recipe</h3>
                    <button type="button" className="btn popup-btn rounded-pill" onClick={() => {
                        setForm(prevForm => ({
                            ...prevForm,
                            phases: []
                        }));
                        setPopupActive(false);
                    }}>Close</button>
                </div>
                <hr></hr>

                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="name">
                            <div className="h6">Method</div>
                        </label>
                        <select
                            className="form-select"
                            aria-label="Name Select"
                            id="name"
                            value={form.name}
                            required
                            onChange={e => setForm({ ...form, name: e.target.value })}>
                            <option value="" disabled>Select a brewing method</option>
                            <option value="aeropress">Aeropress</option>
                            <option value="chemex">Chemex</option>
                            <option value="drip">Drip</option>
                            <option value="french_press">French Press</option>
                            <option value="moka_pot">Moka Pot</option>
                        </select>

                        <label htmlFor="cups">
                            <div className="h6">Cups</div>
                        </label>
                        <select
                            className="form-select"
                            aria-label="Cups Select"
                            id="cups"
                            value={form.cups}
                            required
                            onChange={(e) => handleCupsChange(parseInt(e.target.value), e.target.selectedIndex - 1, form.name)}>
                            <option value="" disabled>Select number of cups</option>
                            {form.name && cupsOptions[form.name].map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="name">
                            <div className="h6">Title</div>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="desc"
                            value={form.desc}
                            required
                            onChange={e => setForm({ ...form, desc: e.target.value })} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="details">Details</label>
                        <textarea
                            type="text"
                            id="details"
                            className="form-control"
                            value={form.details}
                            required
                            onChange={e => setForm({ ...form, details: e.target.value })} />
                    </div>

                    <div className="form-group ingredients">
                        <h5>Ingredients</h5>
                        <hr></hr>
                        <div className="row">
                            <div className="ingd_label">
                                <label htmlFor="coffee" className="col-form-label coffee-label">Coffee Weight (g)</label>
                            </div>
                            <div className="ingd_value">
                                <input
                                    type="number"
                                    id="coffee"
                                    className="form-control"
                                    aria-describedby="passwordHelpInline"
                                    placeholder="Coffee"
                                    required
                                    value={form.ingredients.coffee || ''}
                                    onChange={e => handleIngredients('coffee', parseInt(e.target.value))} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="ingd_label">
                                <label htmlFor="grind" className="col-form-label grind-label">Coffee Grind Size</label>
                            </div>
                            <div className="ingd_value">
                                <select
                                    className="form-select"
                                    id="grind"
                                    aria-label="Default select example"
                                    required
                                    value={form.ingredients.grind}
                                    onChange={e => handleIngredients('grind', e.target.value)} >
                                    <option value="" disabled>Choose a Grind Coarseness</option>
                                    <option value="Fine">Fine</option>
                                    <option value="Fine-Med">Fine-Med</option>
                                    <option value="Medium">Medium</option>
                                    <option value="Med-Coarse">Med-Coarse</option>
                                    <option value="Coarse">Coarse</option>
                                    <option value="Extra-Coarse">Extra-Coarse</option>
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="ingd_label">
                                <label htmlFor="water" className="col-form-label water-label">Water (ml)</label>
                            </div>
                            <div className="ingd_value">
                                <input
                                    type="number"
                                    id="water"
                                    className="form-control"
                                    aria-describedby="passwordHelpInline"
                                    placeholder="Water"
                                    required
                                    value={form.ingredients.water || ''}
                                    onChange={e => handleIngredients('water', parseInt(e.target.value))} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="ingd_label">
                                <label htmlFor="temp" className="col-form-label temp-label">Water Temperature (ºC)</label>
                            </div>
                            <div className="ingd_value">
                                <input
                                    type="number"
                                    id="temp"
                                    className="form-control"
                                    aria-describedby="passwordHelpInline"
                                    placeholder="Temperature"
                                    required
                                    value={form.ingredients.temp || ''}
                                    onChange={e => handleIngredients('temp', parseInt(e.target.value))} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="ingd_label">
                                <label htmlFor="duration" className="col-form-label time-label">Brewing Time (s)</label>
                            </div>
                            <div className="ingd_value">
                                <input
                                    type="number"
                                    id="duration"
                                    className="form-control"
                                    aria-describedby="passwordHelpInline"
                                    placeholder="Brewing Time"
                                    required
                                    value={form.ingredients.duration || ''}
                                    onChange={e => handleIngredients('duration', parseInt(e.target.value))} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="ingd_label">
                                <label htmlFor="ratio" className="col-form-label ratio-label">Coffee : Water Ratio</label>
                            </div>
                            <div className="ingd_value">
                                <input
                                    type="text"
                                    id="ratio"
                                    className="form-control"
                                    aria-describedby="passwordHelpInline"
                                    placeholder="Ratio"
                                    required
                                    value={form.ingredients.ratio || ''}
                                    onChange={e => handleIngredients('ratio', e.target.value)} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="ingd_label">
                                <label htmlFor="beans" className="col-form-label beans-label">Beans</label>
                            </div>
                            <div className="ingd_value">
                                <input
                                    type="text"
                                    id="beans"
                                    className="form-control"
                                    aria-describedby="passwordHelpInline"
                                    placeholder="What Beans did you use?"
                                    value={form.ingredients.beans || ''}
                                    onChange={e => handleIngredients('beans', e.target.value)} />
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex justify-content-between align-items-center">
                                <h5 className="my-0">Phases</h5>
                                <button type="button" className="btn btn-outline-secondary my-0 mx-3" onClick={handlePhaseCount}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"></path>
                                    </svg>
                                </button>
                            </div>
                            <button type="button" className="btn popup-btn rounded-pill align-items-center my-0 me-2" onClick={handlePhaseCount}>Add Phase</button>
                        </div>
                        <hr></hr>
                        {
                            Object.entries(form.phases).map(([phase, { name, duration }], i) => (
                                <div className="row phase_row" key={i}>
                                    <div className="col-form-label col-2 phase_label">
                                        <label htmlFor="phase_name" className="col-form-label">Phase {i + 1}</label>
                                    </div>
                                    <div className="col-7 phase_name">
                                        <textarea
                                            type="text"
                                            id="phase_name"
                                            className="form-control"
                                            value={name}
                                            required
                                            onChange={e => handlePhaseNameChange(e, phase)} />
                                    </div>
                                    <div className="col-2 phase_dur">
                                        <div>
                                            <label htmlFor="phase_duration" className="col-form-label m-0 p-0 phase_dur_label">Duration</label>
                                        </div>
                                        <div>
                                            <input
                                                type="number"
                                                id="phase_duration"
                                                className="form-control m-0"
                                                aria-describedby="passwordHelpInline"
                                                placeholder="10"
                                                required
                                                min="1" // Can't have a zero duration phase
                                                value={duration || ''}
                                                onChange={e => handlePhaseDurationChange(e, phase)} />
                                        </div>
                                    </div>
                                    <div className="col-1">
                                        <button type="button" className="btn btn-danger" onClick={() => handlePhaseDelete(phase)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                                <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            ))
                        }
                        <div className="d-flex justify-content-between align-items-center">
                            <button type="submit" className="btn popup-btn rounded-pill me-2">Submit</button>
                            <button type="button" className="btn popup-btn rounded-pill" onClick={() => setPopupActive(false)}>Close</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddRecipePopup;