import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import { getAuth, onAuthStateChanged, deleteUser, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth'; // Import deleteUser
import { updatePassword } from "firebase/auth"
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { ReactComponent as EditBtn } from '../assets/images/material_icons/edit_square_black_24dp.svg';
import { ReactComponent as ProfileImg } from '../assets/images/material_icons/person_pin_black_24dp.svg';
import { ReactComponent as SaveBtn } from '../assets/images/material_icons/save_black_24dp.svg';
import { ReactComponent as CloseBtn } from '../assets/images/material_icons/close_black_24dp.svg';
import Footer from '../components/Footer';

// Initialize Firebase app
const auth = getAuth();
const firestore = getFirestore();

function Profile() {

    const navigate = useNavigate();

    const [user, setUser] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [likes, setLikes] = useState('');
    const [userBrews] = useState('');
    // const [userCoffees, setUserCoffees] = useState('');
    const [editMode, setEditMode] = useState(false); // State to track edit mode

    // const [password, setPassword] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [reAuthPassword, setReAuthPassword] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [showPasswordModal, setShowPasswordModal] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');

    // const [showConfirmBtn, setShowConfirmBtn] = useState(false);

    const mystyle = {
        borderTopLeftRadius: ".5rem",
        borderBottomLeftRadius: ".5rem",
        padding: "10px",
        fontFamily: "Arial"
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                // User is signed in
                setUser(user);

                // Fetch additional user data from Firestore
                const userDocRef = doc(firestore, 'users', user.uid);
                const docSnap = await getDoc(userDocRef);

                if (docSnap.exists()) {
                    const userData = docSnap.data();
                    setFirstName(userData.firstName);
                    setLastName(userData.lastName);
                    setLikes(userData.likes);
                    // setUserBrews(userData.brews.count);
                    // setUserCoffees(userData.coffees.count);
                } else {
                    console.error('No such document!');
                }
            } else {
                // No user is signed in
                setUser(null);
            }
        });
        // Clean up subscription on unmount
        return () => unsubscribe();
    }, []);

    const toggleEditMode = () => {
        setEditMode(!editMode); // Toggle the edit mode
    };

    const handleUpdateProfile = async () => {
        try {
            // Update user data in Firestore
            const firestore = getFirestore();
            const userDocRef = doc(firestore, 'users', user.uid);
            await setDoc(userDocRef, {
                firstName: firstName,
                lastName: lastName,
            }, { merge: true }); // Merge with existing data if it exists

            console.log('Profile updated successfully!');
            setEditMode(!editMode);
        } catch (error) {
            console.error('Error updating profile:', error);
        }
    };

    const handlePasswordChange = async () => {
        const user = auth.currentUser;

        if (!user) {
            console.error('User is not authenticated');
            return;
        }

        if (newPassword !== confirmPassword) {
            setError('New passwords do not match');
            return;
        }

        try {
            // Re-authenticate the user with their current password
            const credential = EmailAuthProvider.credential(user.email, currentPassword);
            await reauthenticateWithCredential(user, credential);

            // Update the password
            await updatePassword(user, newPassword);
            console.log('Password updated successfully');
            setCurrentPassword('');
            setNewPassword('');
            setConfirmPassword('');
            setError('');
            setShowPasswordModal(false)
        } catch (error) {
            console.error('Error updating password:', error);
            setError(error.message);
        }
    };

    const handleDeleteAccount = async () => {
        if (!reAuthPassword) {
            setPasswordError('Please enter your password to confirm.');
            return;
        }

        setPasswordError('');

        try {
            const user = auth.currentUser;
            const credential = promptUserForReauthentication(user);
            await reauthenticateWithCredential(user, credential);

            await deleteUser(user);
            console.log('User account deleted successfully!');

            setReAuthPassword('');
            navigate("/");
        } catch (error) {
            console.error('Error deleting user account:', error);
        }
    };

    // Function to prompt user for reauthentication
    const promptUserForReauthentication = (user) => {
        return EmailAuthProvider.credential(user.email, reAuthPassword);
    };

    if (!user) {
        return <div>Loading...</div>;
    }

    return (
        <div className='wrapper'>
            <main>
                <Navbar />
                <div className="container">
                    <section className="vh-100" style={{ backgroundColor: "#fff" }}>
                        <div className="container py-5 h-100">
                            <div className="row d-flex justify-content-center align-items-center h-100">
                                <div className="col col-lg-6 mb-4 mb-lg-0">
                                    <div className="card mb-3" style={{ borderRadius: ".5rem" }}>
                                        <div className="row g-0">
                                            <div className="col-md-4 gradient-custom text-center text-white" style={mystyle}>
                                                <ProfileImg fill='white' height={124} width={124} className='img-fluid my-5' />
                                                {!editMode ? (
                                                    <h5>{firstName} {lastName}</h5>
                                                ) : (
                                                    <div>
                                                        <form>
                                                            <div className="form-row">
                                                                <div className="col">
                                                                    <input type="text" className="form-control" placeholder="First name" value={firstName} onChange={(e) => setFirstName(e.target.value)}></input>
                                                                </div>
                                                                <div className="col">
                                                                    <input type="text" className="form-control" placeholder="Last name" value={lastName} onChange={(e) => setLastName(e.target.value)}></input>
                                                                </div>
                                                            </div>
                                                        </form>
                                                        <br />
                                                    </div>
                                                )}
                                                {!editMode ? (
                                                    <EditBtn onClick={toggleEditMode} className="profile-btn" />
                                                ) : (
                                                    <div>
                                                        <CloseBtn onClick={toggleEditMode} className="profile-btn" />
                                                        <SaveBtn onClick={handleUpdateProfile} className="profile-btn" />
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-md-8">
                                                <div className="card-body p-4">
                                                    <h6>Information</h6>
                                                    <hr className="mt-0 mb-4"></hr>
                                                    <div className="row pt-1">
                                                        <div className="col-6 mb-3">
                                                            <h6>Email</h6>
                                                            <p className="text-muted">{user.email}</p>
                                                        </div>
                                                        <div className="col-6 mb-3">
                                                            <h6>Password</h6>
                                                            <button type="button" className="btn popup-btn rounded-pill" onClick={() => setShowPasswordModal(true)}>Update</button>
                                                        </div>
                                                    </div>
                                                    <h6>Activity</h6>
                                                    <hr className="mt-0 mb-4"></hr>
                                                    <div className="row pt-1">
                                                        <div className="col-6 mb-3">
                                                            <h6>Likes</h6>
                                                            <p className="text-muted">{likes}</p>
                                                        </div>
                                                        <div className="col-6 mb-3">
                                                            <h6>Most Viewed</h6>
                                                            <p className="text-muted">{userBrews}</p>
                                                        </div>
                                                        <div className="col-6 mb-3">
                                                            <h6>Delete Account</h6>
                                                            <button type="button" className="btn popup-btn rounded-pill" onClick={() => setShowDeleteModal(true)}>Delete</button>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-start">
                                                        <a href="#!"><i className="fab fa-facebook-f fa-lg me-3"></i></a>
                                                        <a href="#!"><i className="fab fa-twitter fa-lg me-3"></i></a>
                                                        <a href="#!"><i className="fab fa-instagram fa-lg"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Update Password Modal */}
                    {showPasswordModal && (
                        <div className="modal fade show" id="deleteAccountModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-modal="true" style={{ display: 'block' }}>
                            <div className="modal-dialog shadow">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Update Password</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowPasswordModal(false)}></button>
                                    </div>
                                    <div className="modal-body">
                                        <p>Update your password below.</p>
                                        <div className="mb-3">
                                            <input
                                                type="password"
                                                className="form-control"
                                                value={currentPassword}
                                                onChange={(e) => setCurrentPassword(e.target.value)}
                                                placeholder="Enter current password"
                                            />
                                            <input
                                                type="password"
                                                className="form-control"
                                                value={newPassword}
                                                onChange={(e) => setNewPassword(e.target.value)}
                                                placeholder="Enter new password"
                                            />
                                            <input
                                                type="password"
                                                className="form-control"
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                placeholder="Confirm new password"
                                            />
                                            {/* {passwordError && <div className="text-danger">{passwordError}</div>} */}
                                            {error && <p>{error}</p>}
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" onClick={() => setShowPasswordModal(false)}>Cancel</button>
                                        {/* <button type="button" className="btn btn-danger" onClick={handlePasswordUpdate}>Delete</button> */}
                                        <button type="button" className="btn btn-primary" onClick={handlePasswordChange}>Change Password</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    )}
                    {/* Delete Account Modal */}
                    {showDeleteModal && (
                        <div className="modal fade show" id="deleteAccountModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-modal="true" style={{ display: 'block' }}>
                            <div className="modal-dialog shadow ">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Delete Account</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowDeleteModal(false)}></button>
                                    </div>
                                    <div className="modal-body">
                                        <p>Are you sure you want to delete your account? This action cannot be undone.</p>
                                        <div className="mb-3">
                                            <label htmlFor="reAuthPassword" className="form-label">
                                                Enter your password to confirm:
                                            </label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                id="reAuthPassword"
                                                value={reAuthPassword}
                                                onChange={(e) => setReAuthPassword(e.target.value)}
                                            />
                                            {passwordError && <div className="text-danger">{passwordError}</div>}
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" onClick={() => setShowDeleteModal(false)}>Cancel</button>
                                        <button type="button" className="btn btn-danger" onClick={handleDeleteAccount}>Delete</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            {/* <!-- Footer Start --> */}
                <Footer />
            {/* <!-- Footer End --> */}
            </main >
        </div >
    );
}

export default Profile;
