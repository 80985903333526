import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

function TermsAndConditions() {
    return (
        <section className="vh-100">
            <Navbar />
            <div className="d-flex bg-light">
                <div className="container">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="pt-9 col-12 col-md-8 col-lg-10 col-xl-8">
                            <div className="card" style={{ borderRadius: "15px" }}>
                                <div className="card-body p-5">
                                    <h2 className="text-bold text-center">Terms and Conditions</h2>
                                    
                                    <section className="mt-4">
                                        <h3 className="text-bold">Introduction</h3>
                                        <p>Welcome to the Kaffeine app, owned and operated by Ubåt. By accessing or using our app, you agree to comply with and be bound by the following Terms and Conditions, which govern Ubåt's relationship with you in relation to this app. If you do not agree with any part of these terms, please do not use our app.</p>
                                    </section>

                                    <section className="mt-4">
                                        <h3 className="text-bold">End-User License Agreement (EULA) of Kaffeine</h3>
                                        <p>This End-User License Agreement ("EULA") is a legal agreement between you and Ubåt.</p>
                                        <p>This EULA agreement governs your acquisition and use of our Kaffeine software ("Software") directly from Ubåt or indirectly through a Ubåt authorized reseller or distributor (a "Reseller").</p>
                                        <p>Please read this EULA agreement carefully before completing the installation process and using the Kaffeine software. It provides a license to use the Kaffeine software and contains warranty information and liability disclaimers.</p>
                                        <p>If you register for a free trial of the Kaffeine software, this EULA agreement will also govern that trial. By clicking "accept" or installing and/or using the Kaffeine software, you are confirming your acceptance of the Software and agreeing to become bound by the terms of this EULA agreement.</p>
                                        <p>If you are entering into this EULA agreement on behalf of a company or other legal entity, you represent that you have the authority to bind such entity and its affiliates to these terms and conditions. If you do not have such authority or if you do not agree with the terms and conditions of this EULA agreement, do not install or use the Software, and you must not accept this EULA agreement.</p>
                                        <p>This EULA agreement shall apply only to the Software supplied by Ubåt herewith regardless of whether other software is referred to or described herein. The terms also apply to any Ubåt updates, supplements, Internet-based services, and support services for the Software, unless other terms accompany those items on delivery. If so, those terms apply.</p>

                                        <h4 className="text-bold">License Grant</h4>
                                        <p>Ubåt hereby grants you a personal, non-transferable, non-exclusive licence to use the Kaffeine software on your devices in accordance with the terms of this EULA agreement.</p>
                                        <p>You are permitted to load the Kaffeine software (for example a PC, laptop, mobile or tablet) under your control. You are responsible for ensuring your device meets the minimum requirements of the Kaffeine software.</p>

                                        <h4 className="text-bold">You are not permitted to:</h4>
                                        <ul>
                                            <li>Edit, alter, modify, adapt, translate or otherwise change the whole or any part of the Software nor permit the whole or any part of the Software to be combined with or become incorporated in any other software, nor decompile, disassemble or reverse engineer the Software or attempt to do any such things</li>
                                            <li>Reproduce, copy, distribute, resell or otherwise use the Software for any commercial purpose</li>
                                            <li>Allow any third party to use the Software on behalf of or for the benefit of any third party</li>
                                            <li>Use the Software in any way which breaches any applicable local, national or international law</li>
                                            <li>Use the Software for any purpose that Ubåt considers is a breach of this EULA agreement</li>
                                        </ul>

                                        <h4 className="text-bold">Intellectual Property and Ownership</h4>
                                        <p>Ubåt shall at all times retain ownership of the Software as originally downloaded by you and all subsequent downloads of the Software by you. The Software (and the copyright, and other intellectual property rights of whatever nature in the Software, including any modifications made thereto) are and shall remain the property of Ubåt.</p>
                                        <p>Ubåt reserves the right to grant licences to use the Software to third parties.</p>

                                        <h4 className="text-bold">Termination</h4>
                                        <p>This EULA agreement is effective from the date you first use the Software and shall continue until terminated. You may terminate it at any time upon written notice to Ubåt.</p>
                                        <p>It will also terminate immediately if you fail to comply with any term of this EULA agreement. Upon such termination, the licenses granted by this EULA agreement will immediately terminate and you agree to stop all access and use of the Software. The provisions that by their nature continue and survive will survive any termination of this EULA agreement.</p>

                                        <h4 className="text-bold">Governing Law</h4>
                                        <p>This EULA agreement, and any dispute arising out of or in connection with this EULA agreement, shall be governed by and construed in accordance with the laws of [jurisdiction].</p>
                                    </section>

                                    <section className="mt-4">
                                        <h3 className="text-bold">Privacy Policy</h3>
                                        <p>We value your privacy and are committed to protecting your personal data. Please refer to our Privacy Policy for detailed information on how we collect, use, and protect your information.</p>
                                    </section>

                                    <section className="mt-4">
                                        <h3 className="text-bold">Cookies Policy</h3>
                                        <p>Our app uses cookies to enhance your user experience. By using our app, you agree to our use of cookies in accordance with our Cookies Policy.</p>
                                    </section>

                                    <section className="mt-4">
                                        <h3 className="text-bold">Data Protection and GDPR Compliance</h3>
                                        <p>We comply with the General Data Protection Regulation (GDPR). Our servers and data storage are located in the EU. For detailed information on your rights and how we process your data, please refer to our Data Protection Policy.</p>
                                    </section>

                                    <section className="mt-4">
                                        <h3 className="text-bold">Changes to Terms and Conditions</h3>
                                        <p>We may update these Terms and Conditions from time to time. Any changes will be posted on this page, and we will notify you of significant changes through the app or via email.</p>
                                    </section>

                                    <section className="mt-4">
                                        <h3 className="text-bold">Contact Information</h3>
                                        <p>If you have any questions about these Terms and Conditions, please contact us at support@kaffeineapp.com</p>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </section>
    );
}

export default TermsAndConditions;