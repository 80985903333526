import React, { useState } from 'react';
import Card from './Card';

function CardGrid({
    brews,
    loading,
    handleVote,
    name,
    handleNameFilter,
}) {
    const [sortBy, setSortBy] = useState('');
    const [sortOrder, setSortOrder] = useState('desc');
    const [filterBy, setFilterBy] = useState('');
    const [query, setQuery] = useState("");
    const search_params = Object.keys(Object.assign({}, ...brews));
    // const [name, name] = useState('all');

    function search(brews) {

        return brews.filter((brew) =>
            search_params.some((param) => {
                const value = brew[param]; // Access the property value
                return value !== undefined && // Check if the value exists
                    value.toString().toLowerCase().includes(query.toLowerCase());
            })
        );
    }

    const handleSortChange = (e) => {
        const { value: sortByValue, dataset: { order: sortOrderValue } } = e.target.selectedOptions[0];
        setSortBy(sortByValue);
        setSortOrder(sortOrderValue);
    };

    const sortedBrews = [...brews].sort((a, b) => {
        let aValue, bValue;

        if (sortBy === 'votes') {
            aValue = parseFloat(a[sortBy]);
            bValue = parseFloat(b[sortBy]);
        } else if (sortBy === 'date-added') {
            aValue = new Date(a[sortBy]);
            bValue = new Date(b[sortBy]);
        } else if (sortBy === 'username') {
            aValue = a.username ? a.username.toLowerCase() : '';
            bValue = b.username ? b.username.toLowerCase() : '';
        } else {
            aValue = a[sortBy];
            bValue = b[sortBy];
        }
        // Compare aValue and bValue directly
        if (aValue < bValue) {
            return sortOrder === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
            return sortOrder === 'asc' ? 1 : -1;
        }
        return 0;
    });


    // Filter Brews
    const filteredBrews = sortedBrews.filter(brew => {
        // Filter by name
        if (name === 'all' || brew.name === name) {
            return true;
        }
        return false;
    });

    // Filter by name
    const handleFilterChange = (event) => {
        const selectedValue = event.target.value;
        const name = selectedValue;
        setFilterBy(selectedValue);
        handleNameFilter(name);
    };

    return (
        <div className="wrapper bg-light">

            {/* <!-- Mobile Filter --> */}
            <div className="mobile_filter mobile-only row row-cols-3 w-100 bottom-0 bg-dark">
                <div className="button py-3 text-center text-white">
                    <select
                        className="mobile-filters-select text-center py-2"
                        aria-label="Filter Popup"
                        value={filterBy}
                        onChange={handleFilterChange}
                    >
                        <option value="" disabled>Filter:</option>
                        <option value="all">All Methods</option>
                        <option value="aeropress">Aeropress</option>
                        <option value="chemex">Chemex</option>
                        <option value="french_press">French Press</option>
                        <option value="drip">Drip</option>
                        <option value="moka_pot">Moka Pot</option>
                        <option value="default">App Defaults</option>
                    </select>
                </div>
                <div className="button py-3 text-center text-white">
                    <select
                        className="mobile-sort-select text-center py-2"
                        aria-label="Sorting Popup"
                        value={sortBy}
                        onChange={handleSortChange}>
                        <option value="" disabled>Sort by:</option>
                        <option value="votes" data-order="desc">Total Score</option>
                        <option value="username" data-order="asc">Username</option>
                        <option value="date" data-order="desc">Date Added</option>
                        <option value="desc" data-order="asc">Title</option>
                    </select>
                </div>
                <div className="button py-3 text-center text-white">
                    <input
                        type="search"
                        className="form-control form-control-dark"
                        id="mobile-filter"
                        placeholder="Search..."
                        aria-label="Mobile Search"
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                    />
                </div>
            </div>
            {/* <!-- End of Mobile Filter --> */}

            <div className="sort-bar px-3 mb-2 sticky-top border-bottom sticky-offset ">
                <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
                    <form className="search-box desk-only col-lg-auto">
                        <input
                            type="search"
                            className="form-control form-control-dark"
                            id="filter"
                            placeholder="Search..."
                            aria-label="Search"
                            value={query} // bind value to query state
                            onChange={(e) => setQuery(e.target.value)} // update query state
                        />
                    </form>
                    <div className="sort-by text-end desk-only">
                        <select
                            className="sort-select"
                            aria-label="Default sort example"
                            value={sortBy}
                            id="sort-by"
                            onChange={handleSortChange}>
                            <option value="" disabled>Sort by:</option>
                            <option value="votes" data-order="desc">Total Score</option>
                            <option value="username" data-order="asc">Username</option>
                            <option value="date" data-order="desc">Date Added</option>
                            <option value="desc" data-order="asc">Title</option>
                        </select>
                    </div>
                </div>
            </div>
            {loading ? (
                <p>Percolating...</p>
            ) : (
                <div className="recipe-grid row row-cols-1 row-cols-lg-3 row-cols-sm-1 g-2 g-lg-3 px-3 pb-3">
                    {/* Start of Card */}
                    {search(filteredBrews).map((brew, i) => (
                        <Card key={i} brew={brew} onVote={() => handleVote(brew.id)} />
                    ))}
                    {/* End of Card */}
                </div>
            )}
        </div>
    );
}

export default CardGrid;