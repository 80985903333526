import React from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../components/Navbar';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import Footer from '../components/Footer';


function CoffeeDetail() {
    const { state } = useLocation();
    const bean = state?.bean;

    // Add a logging statement here
    console.log("Location state:", state);

    if (!bean) {
        return <div>No data found</div>;
    }
    // const roast_date_cnvrtd = Date(bean.roast_date._seconds)
    const dateObj = new Date(bean.roast_date.seconds * 1000)
    const roast_date_cnvrtd = dateObj.toDateString()
    console.log("date " + dateObj.toISOString())
    console.log("date " + dateObj.toDateString())

    return (
        <div className='wrapper'>
            <div className="dropdown position-fixed bottom-0 end-0 mb-3 me-3 bd-mode-toggle">
                {/* <button className="btn btn-bd-primary py-2 dropdown-toggle d-flex align-items-center" id="bd-theme" type="button"
                    aria-expanded="false" data-bs-toggle="dropdown" aria-label="Toggle theme (auto)">
                    <svg className="bi my-1 theme-icon-active" width="1em" height="1em">
                        <use href="#circle-half"></use>
                    </svg>
                    <span className="visually-hidden" id="bd-theme-text">Toggle theme</span>
                </button> */}
                <ul className="dropdown-menu dropdown-menu-end shadow" aria-labelledby="bd-theme-text">
                    <li>
                        <button type="button" className="dropdown-item d-flex align-items-center" data-bs-theme-value="light"
                            aria-pressed="false">
                            <svg className="bi me-2 opacity-50 theme-icon" width="1em" height="1em">
                                <use href="#sun-fill"></use>
                            </svg>
                            Light
                            <svg className="bi ms-auto d-none" width="1em" height="1em">
                                <use href="#check2"></use>
                            </svg>
                        </button>
                    </li>
                    <li>
                        <button type="button" className="dropdown-item d-flex align-items-center" data-bs-theme-value="dark"
                            aria-pressed="false">
                            <svg className="bi me-2 opacity-50 theme-icon" width="1em" height="1em">
                                <use href="#moon-stars-fill"></use>
                            </svg>
                            Dark
                            <svg className="bi ms-auto d-none" width="1em" height="1em">
                                <use href="#check2"></use>
                            </svg>
                        </button>
                    </li>
                    <li>
                        <button type="button" className="dropdown-item d-flex align-items-center active" data-bs-theme-value="auto"
                            aria-pressed="true">
                            <svg className="bi me-2 opacity-50 theme-icon" width="1em" height="1em">
                                <use href="#circle-half"></use>
                            </svg>
                            Auto
                            <svg className="bi ms-auto d-none" width="1em" height="1em">
                                <use href="#check2"></use>
                            </svg>
                        </button>
                    </li>
                </ul>
            </div>
            <main>
                {/* <!-- NavBar Start --> */}
                <Navbar />
                {/* <!-- NavBar End --> */}
                <div className="container">
                    {/* <!-- Start of Beans Title --> */}
                    <div className="recipe-title ms-3 p-3 pt-9 sticky-top text-center">
                        <h1 className=" mt-3 pt-3">{bean.brand} • {bean.product}</h1>
                        <div className="text-center p-2">
                            <span className="pe-2">⚡️</span>
                            <span className="username pe-2 py-2 border-end">Conor</span>
                            {/* <span className="category pe-2 py-2" data-type={`${brew.category}`}>{brew.category}</span> */}
                            <span className="p-2 border-start">👍</span>
                            <span className="rating">{bean.votes}</span>
                        </div>
                    </div>
                    {/* <!-- End of Beans Title --> */}
                    {/* <!-- Start of Beans Description --> */}
                    <div className="description ms-3 p-3">
                        <p className="fs-5 col-lg-9 mx-auto">{bean.description}</p>
                    </div>
                    {/* <!-- End of Beans Description --> */}

                    <div className="row mx-auto justify-content-md-center">
                        {/* <!-- Start of Roundup --> */}
                        <div className="roundup col-lg-8 ms-3 p-3">
                            <div className="roundup-col">
                                <div className="roundup-row" data-type="coffee_brand">
                                    <span className="roundup-item">Brand</span>
                                    <span className="roundup-value">{bean.brand}</span>
                                </div>
                                <div className="roundup-row" data-type="coffee_product">
                                    <span className="roundup-item">Beans</span>
                                    <span className="roundup-value">{bean.product}</span>
                                </div>
                                <div className="roundup-row" data-type="coffee_pkg_weight">
                                    <span className="roundup-item">Weight</span>
                                    <span className="roundup-value">{bean.weight} g</span>
                                </div>
                                <div className="roundup-row" data-type="roast_profile">
                                    <span className="roundup-item">Roast Profile</span>
                                    <span className="roundup-value">{bean.roast_profile}</span>
                                </div>
                                <div className="roundup-row" data-type="hyperlink">
                                    <span className="roundup-item">Link</span>
                                    <a href={bean.hyperlink}>{bean.hyperlink}</a>
                                </div>
                                <div className="roundup-row" data-type="origin">
                                    <span className="roundup-item">Origin</span>
                                    <span className="roundup-value">{bean.origin}</span>
                                </div>
                                <div className="roundup-row" data-type="process">
                                    <span className="roundup-item">Process</span>
                                    <span className="roundup-value">{bean.process}</span>
                                </div>
                                <div className="roundup-row" data-type="notes">
                                    <span className="roundup-item">Notes</span>
                                    <span className="roundup-value">{bean.notes}</span>
                                </div>
                                <div className="roundup-row" data-type="producer">
                                    <span className="roundup-item">Producer</span>
                                    <span className="roundup-value">{bean.producer}</span>
                                </div>
                                <div className="roundup-row" data-type="grind">
                                    <span className="roundup-item">Suggested Grind</span>
                                    <span className="roundup-value">{bean.grind}</span>
                                </div>
                                <div className="roundup-row" data-type="varietal">
                                    <span className="roundup-item">Varietal</span>
                                    <span className="roundup-value">{bean.varietal}</span>
                                </div>
                                <div className="roundup-row" data-type="altitude">
                                    <span className="roundup-item">Altitude</span>
                                    <span className="roundup-value">{bean.altitude}</span>
                                </div>
                                <div className="roundup-row" data-type="roast_date">
                                    <span className="roundup-item">Roast Date</span>
                                    <span>{roast_date_cnvrtd}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            {/* <!-- Footer Start --> */}
                <Footer />
            {/* <!-- Footer End --> */}
            </main>
        </div >
    );
}

export default CoffeeDetail;