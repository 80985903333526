import React from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { db } from "../firebase.config"
import { useState, useEffect } from "react";
import {
    collection,
    onSnapshot,
    doc,
    addDoc,
    getDoc,
    updateDoc
} from "firebase/firestore"
import { getAuth } from "firebase/auth";
import CardGrid from '../components/CardGrid';
import AddRecipePopup from '../components/AddRecipePopup';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

function BrewPage() {
    // Constants
    const auth = getAuth();
    const user = auth.currentUser;
    const [brews, setBrews] = useState([])
    const [form, setForm] = useState({
        name: "", // Method name, previously category
        // TODO: - cups should be cupsArray and selectedCups, need to change in a few places
        cups: "",
        cupsArray: [0],
        desc: "", // Custom Name added by user. Previously title
        details: "",
        device: "web",
        ingredients: {
            beans: "",
            coffee: 0,
            coffeeArray: [0],
            duration: 0,
            grind: "",
            ratio: "",
            temp: 0,
            water: 0,
            waterArray: [0],
        },
        phases: [],
        userId: "",
        username: "",
        voters: [],
        votes: 0,
    })
    const [popupActive, setPopupActive] = useState(false)
    const [loading, setLoading] = useState(true);
    const brewsCollectionRef = collection(db, "brews");
    const [name, setName] = useState('all');

    // Load Data
    useEffect(() => {
        // Retrieve snapshot from firestore, one time, one webpage load.
        const fetchData = async () => {
            try {
                onSnapshot(brewsCollectionRef, snapshot => {
                    setBrews(snapshot.docs.map(doc => {
                        return {
                            id: doc.id,
                            viewing: false,
                            ...doc.data()
                        }
                    }));
                    setLoading(false); // Data fetching completed, set loading to false
                });
            } catch (error) {
                console.error('Error fetching data: ', error);
                setLoading(false); // Error occurred, set loading to false
            }
        };
        fetchData();

        return () => {
            // Cleanup function if needed
        };
    }, );

    // Function to fetch name and surname of the currently logged-in user
    const getNameAndSurname = () => {
        return new Promise((resolve, reject) => {
            // Check if a user is authenticated
            if (!user) {
                reject(new Error('No user is currently logged in'));
                return;
            }

            // Retrieve UID of the authenticated user
            const uid = user.uid;

            // Reference to the user document in Firestore
            const userRef = doc(db, 'users', uid); // Access the document using doc() function

            // Fetch user document from Firestore
            getDoc(userRef).then((docSnap) => {
                if (docSnap.exists()) {
                    // Extract name and surname fields from the user document
                    const userData = docSnap.data();
                    const firstName = userData.firstName;
                    const lastName = userData.lastName;
                    resolve({ firstName, lastName });
                } else {
                    reject(new Error('User document does not exist'));
                }
            }).catch((error) => {
                reject(error);
            });
        });
    };


    // Submit Form Data
    const handleSubmit = e => {
        e.preventDefault()

        // Array to store names of missing fields
        const missingFields = [];

        // Check if any required field is empty
        if (!form.name) {
            missingFields.push("Brewing method");
        }
        if (!form.cups) {
            missingFields.push("Number of cups");
        }
        if (!form.desc) {
            missingFields.push("Desc");
        }
        if (!form.details) {
            missingFields.push("Details");
        }

        if (!form.ingredients.coffee) {
            missingFields.push("Coffee");
        }

        if (!form.ingredients.grind) {
            missingFields.push("Coffee");
        }

        if (!form.ingredients.water) {
            missingFields.push("Water");
        }

        if (!form.ingredients.temp) {
            missingFields.push("Temperature");
        }

        if (!form.ingredients.duration) {
            missingFields.push("Duration");
        }

        if (!form.ingredients.ratio) {
            missingFields.push("Ratio");
        }

        // Check phases
        if (form.phases.length === 0) {
            missingFields.push("At least one phase");
        } else {
            form.phases.forEach((phase, index) => {
                if (phase.phase.trim() === "") {
                    missingFields.push(`Phase ${index + 1} name`);
                }
                if (phase.duration === 0) {
                    missingFields.push(`Phase ${index + 1} duration`);
                }
            });
        }

        // If any field is missing, show alert with missing fields
        if (missingFields.length > 0) {
            alert(`Please fill out all fields. Missing: ${missingFields.join(', ')}`);
            return;
        }

        // For now beans is optional so commenting out this check
        // if (!form.ingredients.beans) {
        //     missingFields.push("Beans");
        // }

        if (form.phases.length === 0) {
            missingFields.push("Phases");
        }

        // If any field is missing, show alert with missing fields
        if (missingFields.length > 0) {
            alert(`Please fill out all fields. Missing: ${missingFields.join(', ')}`);
            return;
        }

        console.log(
            `
             name: ${form.name}
             desc: ${form.desc.trim().length !== 0}
             details: ${form.details.trim().length !== 0}
             ingredients: ${form.ingredients.length !== 0}
             phases: ${form.phases.length !== 0}
            `
        );

        getNameAndSurname()
            .then(({ firstName, lastName }) => {
                // Once we have the name and surname, we can add a document to Firestore
                addDoc(brewsCollectionRef, {
                    ...form,
                    date: firebase.firestore.FieldValue.serverTimestamp(),
                    username: firstName + ' ' + lastName, // Combine name and surname
                    userId: user.uid,
                    votes: 0,
                })
                    .then((docRef) => {
                        console.log("Document written with ID: ", docRef.id);
                    })
                    .catch((error) => {
                        console.error("Error adding document: ", error);
                    });
            })
            .catch((error) => {
                console.error('Error:', error.message);
            });

        // reset the form the defaults.
        setForm({
            name: "",
            cups: [0],
            cupsArray: [0],
            details: "",
            ingredients: {
                beans: "",
                coffee: 0,
                coffeeArray: [0],
                duration: 0,
                grind: "",
                ratio: "",
                temp: 0,
                water: 0,
                waterArray: [0],
            },
            phases: [],
            desc: "",
            userId: "",
            username: "",
            voters: [],
            votes: 0,
        })

        // Close the add recipe popup
        setPopupActive(false)
    }

    const [cupsOptions] = useState({
        aeropress: [1, 2],
        chemex: [3, 6, 8],
        drip: [2, 4],
        french_press: [3, 4, 8, 12],
        moka_pot: [3, 6, 8, 12],
    });

    //add Ingreditents to array, but run handle cups first
    const handleIngredients = (field, value) => {
        setForm(prevForm => {
            // Check if the field being updated is 'coffee' or 'water'
            if (field === 'coffee' || field === 'water') {

                const updatedIngredients = {
                    ...prevForm.ingredients,
                    [field]: value
                };

                // Check if both 'name' and 'cups' are set in prevForm
                if (prevForm.name && prevForm.cups) {
                    // Find the index of the selected cups in the cupsOptions array
                    console.log(prevForm.cups)
                    const cupsIndex = cupsOptions[prevForm.name].indexOf(prevForm.cups);

                    if (cupsIndex !== -1) {
                        // Call handleCupsChange with the updated cups, selected index, and name
                        handleCupsChange(prevForm.cups, cupsIndex, prevForm.name);
                    } else {
                        console.error(`Selected cups (${prevForm.cups}) not found in cupsOptions for name: ${prevForm.name}`);
                    }

                } else {
                    console.error('Name or cups not set in prevForm');
                }

                return {
                    ...prevForm,
                    ingredients: updatedIngredients
                };
            } else {
                // For other fields, just update the ingredients without calling handleCupsChange
                return {
                    ...prevForm,
                    ingredients: {
                        ...prevForm.ingredients,
                        [field]: value
                    }
                };
            }
        });
    };

    const handleCupsChange = (selectedCups, selectedIndex, name) => {
        // setForm({ ...form, cups: selectedCups });
        console.log("Running handleCupsChange")
        console.log(name)
        setForm(prevForm => {

            // Calculate ratios
            const coffeeRatio = prevForm.ingredients.coffee / selectedCups;
            const waterRatio = prevForm.ingredients.water / selectedCups;

            if (!cupsOptions[name]) {
                // Handle the case where cupsOptions[name] is undefined
                console.error(`Name '${name}' is not found in cupsOptions`);
                return prevForm;
            }

            let newCoffeeArray = new Array(cupsOptions[name].length).fill(0)
            let newWaterArray = new Array(cupsOptions[name].length).fill(0)

            newCoffeeArray[selectedIndex] = prevForm.coffee
            newWaterArray[selectedIndex] = prevForm.water

            // Calculate and assign values for each index
            cupsOptions[name].forEach((cups, index) => {
                if (index === selectedIndex) {
                    // Assign the calculated values at the selected index
                    newCoffeeArray[index] = parseInt(prevForm.ingredients.coffee);
                    newWaterArray[index] = parseInt(prevForm.ingredients.water);
                } else {
                    newCoffeeArray[index] = Math.round(cupsOptions[name][index] * coffeeRatio);
                    newWaterArray[index] = Math.round(cupsOptions[name][index] * waterRatio);
                }
            });

            console.log(newCoffeeArray, newWaterArray)

            return {
                ...prevForm,
                cups: selectedCups,
                ingredients: {
                    ...prevForm.ingredients,
                    coffeeArray: newCoffeeArray,
                    waterArray: newWaterArray
                }
            };
        });
    };

    // Allow changing phase name
    const handlePhaseNameChange = (e, index) => {
        const newPhases = [...form.phases];
        newPhases[index] = { ...newPhases[index], phase: e.target.value };
        setForm({ ...form, phases: newPhases });
    };

    // Allow changing of phase duration
    const handlePhaseDurationChange = (e, index) => {
        const newPhases = [...form.phases];
        newPhases[index] = { ...newPhases[index], duration: parseInt(e.target.value) };
        setForm({ ...form, phases: newPhases });
    };

    // Delete the phases
    const handlePhaseDelete = (phaseToDelete) => {
        setForm(prevForm => ({
            ...prevForm,
            phases: prevForm.phases.filter((_, index) => index !== parseInt(phaseToDelete))
        }));
    };

    // Amount of phases
    const handlePhaseCount = () => {
        setForm({
            ...form,
            phases: [
                ...form.phases,
                { duration: 0, phase: "" } // Initialize a new phase with default values
            ]
        });
    };

    // Filter by Name
    const handleNameFilter = (name) => {
        setName(name);
    };

    // Allow users to vote on brew. Only allow vote one time per user. Must be logged in
    const handleVote = async (docId) => {
        try {
            const auth = getAuth();
            const user = auth.currentUser;

            if (!user) {
                console.error("User not authenticated.");
                return;
            }

            const brewRef = doc(db, "brews", docId);
            const docSnap = await getDoc(brewRef);

            if (docSnap.exists()) {
                const { votes, voters } = docSnap.data();

                // Check if the user has already voted
                if (voters && voters.hasOwnProperty(user.uid)) {
                    console.log("User has already voted.");
                    return; // Exit function if user has already voted
                }

                // If user has not voted, update the document
                const newVoters = { ...voters, [user.uid]: true };

                await updateDoc(brewRef, {
                    votes: votes + 1,
                    voters: newVoters
                });

                console.log('Vote added successfully');
            } else {
                console.error('Document does not exist');
            }
        } catch (error) {
            console.error('Error adding vote:', error);
        }
    };

    return (

        <main>
            {/* <!-- NavBar Start --> */}
            <Navbar />
            {/* <!-- NavBar End --> */}

            {/** Blurb Start */}
            <div className="blurb ms-3 m-3 p-3">
                <h1 className="text-body-emphasis pt-9">Recipes</h1>
                <p className="fs-5 col-md-8">Here you'll find all the recipes used by Kaffeine as well as recipes submitted by the community.
                    To submit your own recipe you need to create an account and then you can submit a recipe either through the app, or by clicking on the "Add Recipe" button.
                    Enjoy!
                </p>
                {user && <button type="button" className="btn popup-btn rounded-pill" onClick={() => {
                    setForm(prevForm => ({
                        ...prevForm,
                        phases: []
                    }));
                    setPopupActive(!popupActive);
                }}>Add recipe</button>}

            </div>
            {/** Blurb End */}

            <div className="flex w-100">

                {/** Sidebar */}
                <div className="container sidebar desk-only sticky-top sticky-offset ">
                    <div className="flex flex-column top sticky-top sticky-offset ">
                        <span className="pb-3 mt-3 border-bottom">Method</span>
                        <ul className="me-auto pt-3">
                            <li className={`rounded filter-btn ${name === 'all' ? 'active' : ''}`} onClick={() => handleNameFilter('all')} data-type="all">All Methods</li>
                            <li className={`rounded filter-btn ${name === 'aeropress' ? 'active' : ''}`} onClick={() => handleNameFilter('aeropress')} data-type="aeropress">Aeropress</li>
                            <li className={`rounded filter-btn ${name === 'chemex' ? 'active' : ''}`} onClick={() => handleNameFilter('chemex')} data-type="chemex">Chemex</li>
                            <li className={`rounded filter-btn ${name === 'french_press' ? 'active' : ''}`} onClick={() => handleNameFilter('french_press')} data-type="french_press">French Press</li>
                            <li className={`rounded filter-btn ${name === 'drip' ? 'active' : ''}`} onClick={() => handleNameFilter('drip')} data-type="drip">Drip</li>
                            <li className={`rounded filter-btn ${name === 'moka_pot' ? 'active' : ''}`} onClick={() => handleNameFilter('moka_pot')} data-type="moka_pot">Moka Pot</li>
                            <li className={`rounded filter-btn ${name === 'default' ? 'active' : ''}`} onClick={() => handleNameFilter('default')} data-type="default">Kaffeine Defaults</li>
                        </ul>
                    </div>
                </div>
                {/** End of Sidebar */}

                {/** Start of Brew Grid */}
                <CardGrid
                    brews={brews}
                    loading={loading}
                    handleVote={handleVote}
                    name={name}
                    handleNameFilter={handleNameFilter}
                />
                {/** End of Brew Grid */}

            </div>

            {/* <!-- Start of Add Recipe popup --> */}

            <AddRecipePopup
                isOpen={popupActive}
                onClose={() => {
                    setForm(prevForm => ({
                        ...prevForm,
                        phases: []
                    }));
                    setPopupActive(false);
                }}
                form={form}
                popupActive={popupActive}
                setForm={setForm}
                handleSubmit={handleSubmit}
                handleIngredients={handleIngredients}
                handlePhaseNameChange={handlePhaseNameChange}
                handlePhaseDurationChange={handlePhaseDurationChange}
                handlePhaseCount={handlePhaseCount}
                setPopupActive={setPopupActive}
                handleCupsChange={handleCupsChange}
                cupsOptions={cupsOptions}
                handlePhaseDelete={handlePhaseDelete}  // Add this line
            />

            {/* <!-- End of Add Recipe popup --> */}

            {/* <!-- Footer Start --> */}
                        <Footer />
            {/* <!-- Footer End --> */}

        </main>
    );
}
export default BrewPage;
