import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from "../firebase.config";
import { collection, doc, setDoc } from 'firebase/firestore';
import { db } from "../firebase.config";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

function Join() {

    const navigate = useNavigate();

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = async (e) => {
        e.preventDefault()

        await createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                console.log(user);

                const usersCollectionRef = collection(db, "users");

                const userData = {
                    email: user.email,
                    firstName: firstName,
                    lastName: lastName,
                    likes: 0,
                    subscribed: false,
                    subscriptionPlan: "free",
                    brews: [],
                    coffees: []
                    // Add any other user data you want to store
                };

                const userDocRef = doc(usersCollectionRef, user.uid);

                setDoc(userDocRef, userData)
                    .then(docRef => {
                        console.log('User added to Firestore successfully');
                    })
                    .catch(error => {
                        console.error('Error adding document: ', error);
                    });

                navigate("/")
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
                // ..
            });
    }

    return (
        <section className="vh-100">
            <Navbar />
            <div className="d-flex h-100  bg-light">
                <div className="container h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                            <div className="card" style={{ borderRadius: "15px" }}>
                                <div className="card-body p-5">
                                    <h2 className="text-bold text-center">Welcome to Kaffeine</h2>
                                    <p className="d-flex justify-content-center text-center text-muted mt-3 mb-4">
                                        <span>Create a free account or</span>
                                        <div className="join-link ms-2"><Link to='/login/'>Log In</Link></div>
                                    </p>
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-2">
                                            <label htmlFor="first_name" className="pb-2 fw-bold">First Name</label>
                                            <input
                                                type="text"
                                                label="First Name"
                                                id="first_name"
                                                value={firstName}
                                                onChange={(e) => setFirstName(e.target.value)}
                                                required
                                                className="form-control"
                                            />
                                        </div>

                                        <div className="mb-4">
                                            <label htmlFor="first_name" className="pb-2 fw-bold">Last Name</label>

                                            <input
                                                type="text"
                                                label="Last Name"
                                                id="Last Name"
                                                value={lastName}
                                                onChange={(e) => setLastName(e.target.value)}
                                                required
                                                className="form-control"
                                            />
                                        </div>

                                        <div className="mb-2">
                                            <label htmlFor="email" className="pb-2 fw-bold">Email</label>
                                            <input
                                                type="email"
                                                label="Email Address"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                                className="form-control"
                                            />
                                        </div>

                                        <div className="mb-2">
                                            <div className="d-flex justify-content-between">
                                                <label htmlFor="password" className="pb-2 fw-bold">Password</label>
                                                <label htmlFor="show-password" className="pb-2 show-password" onClick={togglePasswordVisibility}>{showPassword ? "Hide" : "Show"} Password</label>
                                            </div>
                                            <input
                                                type={showPassword ? "text" : "password"}
                                                label="Create Password"
                                                id="password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                required
                                                className="form-control"
                                            />
                                        </div>

                                        <div className="form-check d-flex justify-content-center mb-3 mt-3">
                                            <input
                                                className="form-check-input me-2"
                                                type="checkbox"
                                                value=""
                                                id="form2Example3cg"
                                                required
                                            />
                                            <label className="form-check-label" htmlFor="form2Example3g">
                                                By creating an account you agree with our <a href="#!" className="text-body"><u>Terms of service,</u></a>
                                            </label>
                                        </div>

                                        <div className="d-flex justify-content-center">
                                            <button type="submit" className="btn popup-btn rounded-pill me-2">Register</button>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Footer Start --> */}
                <Footer />
            {/* <!-- Footer End --> */}
        </section>
    );

}

export default Join;
