import React from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { db } from "../firebase.config"
import { useState, useEffect } from "react";
import {
    collection,
    onSnapshot,
    doc,
    addDoc,

    getDoc,
    updateDoc
} from "firebase/firestore"
import { getAuth } from "firebase/auth";
import CardGridCoffee from '../components/CardGridCoffee';
import AddCoffeePopup from '../components/AddCoffeePopup';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import 'react-datepicker/dist/react-datepicker.css'; // Import the styles

function CoffeePage() {
    const auth = getAuth();
    const user = auth.currentUser;  
    const [beans, setBeans] = useState([])
    const [form, setForm] = useState({
        brand: "",
        product: "",
        description: "",
        weight: "",
        roast_profile: "",
        roast_date: "",
        hyperlink: "",
        origin: "",
        varietal: '',
        altitude: "",
        process: "",
        notes: "",
        producer: "",
        grind: "",
        votes: 0,
        country: "",
        category: "",
        count: ""
    })
    const [popupActive, setPopupActive] = useState(false)
    const [loading, setLoading] = useState(true);
    const beansCollectionRef = collection(db, "coffee");
    // const newCsv = ["Fjord Coffee Roasters", "Puente Solaya", "The Solaya hill separates the 17 different producing families, and is a great metaphor of a link, a nexus, a bridge (puente) between  these communities that share a great coffee potential and people with great hospitality. ", "250", "Medium", "https://fjord-coffee.de/", "Cajamarca, Cutervo, Callayuc, Peru", "Fermentation of 48+ hours. Washed.", "Milk Chocolate | Cherry | Clementine", "Puente Solaya community", "Whole Bean", "Caturra and Pache", "1800 - 2000 meters", "europe", "craft"]

    // const [form3, setForm3] = useState({
    //     brand: newCsv[0],
    //     product: newCsv[1],
    //     description: newCsv[2],
    //     weight: newCsv[3],
    //     roast_profile: newCsv[4],
    //     hyperlink: newCsv[5],
    //     origin: newCsv[6],
    //     process: newCsv[7],
    //     notes: newCsv[8],
    //     producer: newCsv[9],
    //     grind: newCsv[10],
    //     varietal: newCsv[11],
    //     altitude: newCsv[12],
    //     country: newCsv[13],
    //     category: newCsv[14],
    //     votes: 0,
    //     count: 0
    // })

    useEffect(() => {
        const fetchData = async () => {
            try {
                onSnapshot(beansCollectionRef, snapshot => {
                    setBeans(snapshot.docs.map(doc => {
                        return {
                            id: doc.id,
                            viewing: false,
                            ...doc.data()
                        }
                    }));
                    setLoading(false); // Data fetching completed, set loading to false
    
                    // Save data to local storage after fetching
                    localStorage.setItem('beansData', JSON.stringify(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))));
                });
            } catch (error) {
                console.error('Error fetching data: ', error);
                setLoading(false); // Error occurred, set loading to false
            }
        };
        fetchData();
    
        return () => {
            // Cleanup function if needed
        };
    }, );

    // const handleDownloadData = () => {
    //     const storedData = localStorage.getItem('beansData');
    //     if (storedData) {
    //         // Create a blob containing the data
    //         const blob = new Blob([storedData], { type: 'application/json' });

    //         // Create a temporary anchor element
    //         const a = document.createElement('a');
    //         a.href = URL.createObjectURL(blob);
    //         a.download = 'beans_data.json';
    //         document.body.appendChild(a);

    //         // Trigger the click event on the anchor element
    //         a.click();

    //         // Cleanup
    //         document.body.removeChild(a);
    //     }
    // };

    // const handleUploadData = async () => {
    //     // Retrieve data from Local Storage
    //     const storedData = localStorage.getItem('beansData');
    //     if (storedData) {
    //         // Upload data to Firestore
    //         const parsedData = JSON.parse(storedData);
    //         parsedData.forEach(async docData => {
    //             try {
    //                 await setDoc(doc(beansCollectionRef, docData.id), docData);
    //                 console.log('Document uploaded successfully:', docData.id);
    //             } catch (error) {
    //                 console.error('Error uploading document:', docData.id, error);
    //             }
    //         });
    //     }
    // };

    const [category, setCategory] = useState('all');
    const [country, setCountry] = useState('all');


    
    const handleDateChange = date => {
        setForm(prevState => ({
            ...prevState,
            roast_date: date
        }));
        console.log(form.roast_date)
    };

    const handleSubmit = e => {
        e.preventDefault()

        addDoc(beansCollectionRef, {
            ...form,
            date: firebase.firestore.FieldValue.serverTimestamp()
        })

        setForm({
            brand: "",
            product: "",
            description: "",
            weight: "",
            roast_profile: "",
            roast_date: "",
            level: "",
            hyperlink: "",
            origin: "",
            varietal: '',
            altitude: "",
            process: "",
            notes: "",
            producer: "",
            grind: "",
            country: "",
            category: "",
        })

        setPopupActive(false)
    }

    // const handleSubmit3 = e => {
    //     e.preventDefault()

    //     console.log("Submit 3");

    //     let printObj = function (obj) {
    //         let string = '';

    //         for (let prop in obj) {
    //             if (typeof obj[prop] == 'string') {
    //                 string += prop + ': ' + obj[prop] + '; \n';
    //             }
    //             else {
    //                 // string += prop + ': { \n' + print(obj[prop]) + '}';
    //             }
    //         }

    //         return string;
    //     }

    //     console.log(printObj(form3));

    //     addDoc(beansCollectionRef, {
    //         ...form3,
    //         roast_date: firebase.firestore.FieldValue.serverTimestamp()
    //     })

    //     setForm({
    //         brand: "",
    //         product: "",
    //         description: "",
    //         weight: "",
    //         roast_profile: "",
    //         roast_date: "",
    //         level: "",
    //         hyperlink: "",
    //         origin: "",
    //         varietal: '',
    //         altitude: "",
    //         process: "",
    //         notes: "",
    //         producer: "",
    //         grind: "",
    //         country: "",
    //         category: ""
    //     })
    // }

    const handleVote = async (docId) => {
        try {
            const auth = getAuth();
            const user = auth.currentUser;

            if (!user) {
                console.error("User not authenticated.");
                return;
            }

            const beanRef = doc(db, "coffee", docId);
            const docSnap = await getDoc(beanRef);

            if (docSnap.exists()) {
                const { votes, voters } = docSnap.data();

                // Check if the user has already voted
                if (voters && voters.hasOwnProperty(user.uid)) {
                    console.log("User has already voted.");
                    return; // Exit function if user has already voted
                }

                // If user has not voted, update the document
                const newVoters = { ...voters, [user.uid]: true };

                await updateDoc(beanRef, {
                    votes: votes + 1,
                    voters: newVoters
                });

                console.log('Vote added successfully');
            } else {
                console.error('Document does not exist');
            }
        } catch (error) {
            console.error('Error adding vote:', error);
        }
    };

    const handleCategoryCountryFilter = (category, country) => {
        setCategory(category);
        setCountry(country);
    };

    return (

        <main>
            {/* <!-- NavBar Start --> */}
            <Navbar />
            {/* <!-- NavBar End --> */}

            {/** Blurb Start */}
            <div className="blurb ms-3 m-3 p-3">
                <h1 className="text-body-emphasis pt-9">Coffee</h1>
                <p className="fs-5 col-md-8">Here you'll find a database of all the Coffee beans used by Kaffeine users. The goal is to create the most up to date and largest database of craft coffee roasters in the world.
                    To submit your own beans you need to create an account and then you can submit Coffee either through the app, or on the form available on the submit page.
                    Enjoy!
                </p>
                { user && <button  type="button" className="btn popup-btn rounded-pill me-2" onClick={() => setPopupActive(!popupActive)}>Add beans</button> }
                {/* <button onClick={handleDownloadData}>Download Data</button> */}
                {/* <button type="button" className="btn popup-btn rounded-pill" onClick={handleSubmit3}>Add Custom beans</button> */}
                {/* <button onClick={handleUploadData}>Upload Data to EU</button> */}

            </div>
            {/** Blurb End */}

            <div className="flex w-100">

                {/** Sidebar */}
                <div className="container sidebar desk-only sticky-top sticky-offset ">
                    <div className="flex flex-column top sticky-top sticky-offset ">
                        <span className="pb-3 mt-3 border-bottom">Filters</span>
                        <ul className="me-auto pt-3">
                            <li className={`rounded filter-btn ${category === 'all' && country === 'all' ? 'active' : ''}`} onClick={() => { handleCategoryCountryFilter('all', 'all'); }} data-type="all">All Coffees</li>
                            <li className={`rounded filter-btn ${country === 'usa' ? 'active' : ''}`} onClick={() => { handleCategoryCountryFilter('all', 'usa'); }} data-type="usa">USA</li>
                            <li className={`rounded filter-btn ${country === 'europe' ? 'active' : ''}`} onClick={() => { handleCategoryCountryFilter('all', 'europe'); }} data-type="europe">Europe</li>
                            <li className={`rounded filter-btn ${country === 'uk' ? 'active' : ''}`} onClick={() => { handleCategoryCountryFilter('all', 'uk'); }} data-type="uk">UK</li>
                            <li className={`rounded filter-btn ${country === 'rest_of_world' ? 'active' : ''}`} onClick={() => { handleCategoryCountryFilter('all', 'rest_of_world'); }} data-type="rest_of_world">Rest of World</li>
                            <li className={`rounded filter-btn ${category === 'craft' ? 'active' : ''}`} onClick={() => { handleCategoryCountryFilter('craft', 'all'); }} data-type="craft">Crafty</li>
                            <li className={`rounded filter-btn ${category === 'supermarket' ? 'active' : ''}`} onClick={() => { handleCategoryCountryFilter('supermarket', 'all'); }} data-type="supermarket">Supermarket</li>
                            <li className={`rounded filter-btn ${category === 'chain' ? 'active' : ''}`} onClick={() => { handleCategoryCountryFilter('chain', 'all'); }} data-type="chain">Chain</li>
                        </ul>
                    </div>
                </div>
                {/** End of Sidebar */}

                {/** Start of Beans Grid */}
                <CardGridCoffee
                    beans={beans}
                    loading={loading}
                    handleVote={handleVote}
                    category={category}
                    country={country}
                    handleCategoryCountryFilter={handleCategoryCountryFilter}
                />                
                {/** End of Beans Grid */}
                
            </div>

            {/* <!-- Start of Add Recipe popup --> */}

            <AddCoffeePopup
                isOpen={popupActive}
                onClose={() => setPopupActive(false)}
                form={form}
                popupActive={popupActive}
                setForm={setForm}
                handleDateChange={handleDateChange}
                handleSubmit={handleSubmit}
                setPopupActive={setPopupActive}
            />

            {/* <!-- End of Add Recipe popup --> */}

            {/* <!-- Footer Start --> */}
                <Footer />
            {/* <!-- Footer End --> */}
        </main>
    );
}

export default CoffeePage;

